<template>
  <div>
    <div class="container my-2" v-if="auth().meta && !auth().meta.is_verified">
      <!-- User Information-->
      <div class="alert alert-warning alert-dismissible fade show">
        Your account is not verified yet. This might affect your consideration for loans.
        <strong><router-link class="" to="/verify">Verify now</router-link></strong> by updating your profile and providing us with your BVN.
        <br/>
        <div class="d-flex justify-content-end">
          <router-link class="btn m-1 btn-sm btn-warning shadow" v-if="userProfile() && !userProfile().dob" to="profile">Update Profile</router-link>
          <router-link class="btn m-1 btn-sm btn-warning shadow" to="/verify">
            Verifiy Now!
          </router-link>
        </div>
        <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
    </div>

    <div class="container mt-2 mb-4">
      <div class="card">
        <div class="card-header">
            <h6 class="py-1 fw-bold"><i class="pi pi-sun"></i> Loan History</h6>
        </div>
        <div class="card-body" v-if="userLoanPlans() && userLoanPlans().length > 0">
          <div class="accordion accordion-flush accordion-style-two" id="accordionStyle2">
            <!-- Single Accordion -->
            <div class="accordion-item" v-for="(plan, i) in userLoanPlans()" :key="i">
              <div class="accordion-header" :id="`accordion${i}`">
                <div class="mt-2 d-flex justify-content-between">
                  <span>{{ plan.subscription.name + " (" + plan.name + ")" }}</span>
                  <h6 
                    data-bs-toggle="collapse" 
                    :data-bs-target="`#accordionStyleCol${i}`" 
                    aria-expanded="false" 
                    :aria-controls="`accordionStyleCol${i}`" 
                    class="collapsed"
                    style="display:block;width:1.5rem;padding:0px;"
                  >
                    <i class="bi bi-plus-lg"></i>
                  </h6>
                </div>

                <div class="d-flex justify-content-between mt-2">
                  <span :style="{color: getPlanBalance(plan) < 0 ? 'red' : 'var(--jw-text-success)'}" class="">
                    {{ formatCurrency({amount: getPlanBalance(plan), currency: plan.subscription.currency}) }}
                  </span>
                  <p class="text-sm text-muted">
                    <small>
                      {{ moment(plan.subscription.updated_at || plan.subscription.updated_at).format("DD MMM, HH:mm") }}
                    </small>
                    &nbsp;
                    <span class="badge rounded-pill" :class="`${getStatusColor(plan.subscription.status)}`">{{ plan.subscription.status }}</span>
                  </p>
                </div>
              </div>

              <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle2" style="">
                <div class="accordion-body">
                    
                  <p class="mb-0">{{ plan.subscription.comment }}</p>
                  <h4 class="badge bg-dark">
                      {{ plan.subscription.base_currency }} {{ plan.subscription.base_currency_amount.toFixed(2) }} 
                      | {{ plan.duration_unit === "day" ? "daily" : plan.duration_unit + "ly" }} loan
                      <!-- {{ plan.subscription.base_currency }} {{( plan.subscription.rate * plan.subscription.amount).toFixed(2) }} -->
                  </h4>
                  <!-- <p>{{ plan.subscription.currency }} rate: {{ plan.subscription.rate }} {{ plan.subscription.base_currency }}</p> -->
                  <p>
                    Purpose: {{ plan.subscription.name || "" }} <br/> 
                    Paid: <b>{{ formatCurrency({amount: getPlanBalance(plan), currency: plan.subscription.currency}) }}</b> <br/>
                    Debt: {{ plan.subscription.status === "ACTIVE" ? formatCurrency({currcny: plan.subscription.currency, amount: getPlanSpent(plan)}) : 0 }} <br/>
                    Duration: {{ plan.duration }} {{ plan.duration_unit }}  <br/>
                    Funding Source: {{ plan.subscription.funding_source }}
                  </p>

                  <p>Loan plan: </p>
                  <p class="mb-0" v-if="plan.subscription.loans">
                  
                      <table class="table mb-0 table-hover" >
                          <thead>
                              <th class="text-center">
                                  Amount
                              </th>
                              <th class="text-center">
                                  status
                              </th>
                              <th class="text-center">
                                  Due At
                              </th>
                          </thead>
                          <tbody>
                              <tr 
                                  v-for="(loan, j) in plan.subscription.loans" 
                                  :key="j" 
                                  :class="`tb-bg-${loan.status}`" 
                                  :title="loan.status" 
                                  @mouseover="activeLoanIdx=j; selectPayment(plan, loan)" 
                                  @mouseout="activeLoanIdx=null"
                              >
                                  <td>
                                      <div class="text-xs">
                                          {{ plan.subscription.currency }} {{ Math.round(loan.amount * 10)/10 }} 
                                      </div>
                                  </td>
                                  <td>
                                      <div class="text-xs">
                                          {{ loan.status }}
                                      </div>
                                  </td>
                                  <td class="text-center">
                                      <div class="text-xs" v-if="activeLoanIdx==j && ['PENDING', 'DUE'].includes(loan.status)" >
                                        <button 
                                          class="btn btn-sm btn-success" 
                                          :disabled="moment(loan.due_at).isAfter(new Date)" 
                                          :id="`pay-button-${loan.id}`"
                                          data-bs-toggle="modal" 
                                          data-bs-target="#pay-loan" 
                                        >pay!</button>
                                      </div>
                                      <div v-else>
                                          {{ moment(loan.due_at).format("DD MMM HH:mm a") }}
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </p>
                  <br/>
                  <div class="mt-1 text-end">
                    <button 
                    :id="`terminate-button-${plan.subscription.id}`"
                    class="btn btn-warning btn-sm btn-creative" 
                    data-bs-toggle="modal" 
                    data-bs-target="#terminate" 
                    @click="showTerminateModal(plan)" 
                    v-if="plan.subscription.status ==='PENDING' || plan.subscription.status ==='INITIATED'" 
                    :disabled="plan.subscription.status!=='PENDING' && plan.subscription.status !=='INITIATED'">terminate</button>
                  </div>
                </div>
              </div>
              <hr/>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
            <div class="text-center">
                <i class="bi bi-clipboard-x"></i>
                You can start by requesting a loan <br/>
                <small>
                  <router-link to="/loans" style="text-decoration: none; color: var(--jw-text-success)"> Request Loan. </router-link>
                </small>
                <img src="../../../assets/images/loan/empty-loan.png" />
            </div>
        </div>
      </div>

      <modal title="Terminate Loan" id="terminate">
        <div v-if="Object.values(loanPlan).length > 0">
          <h6>
            You are about to terminate your loan plan "<b>{{loanPlan.subscription.name}}</b>"
          </h6>
          <i class="bi bi-clock"></i> Due date: <b>{{ moment(loanPlan.subscription.loans[loanPlan.subscription.loans.length - 1].due_at).format("DD MMM HH:mm a") }}</b>
          <div v-if="loanPlan.is_flexible || moment(loanPlan.subscription.loans[loanPlan.subscription.loans.length - 1].due_at).isBefore(new Date)">
            <i class="bi bi-cash-coin"></i> Termination Fee: 0
          </div>
          <div v-else>
            <i class="bi bi-cash-coin"></i> Termination before the due date will cost you <b>{{ loanPlan.opt_out_penalty }}%</b> of your loan amount.
            <span></span>
          </div>
          <div class="text-end mt-2">
            <app-button @click="stopLoan()" :classes="'btn-sm btn-danger'" :title="'terminate'" :loading="loaders().abortPlan" :disabled="loaders().abortPlan" ></app-button>
          </div>
        </div>
      </modal>

      <modal title="Pay Loan" id="pay-loan" @close="paymentType=null">
        <div v-if="!paymentType">
          <div class="text-center"> 
            <h6>Payment method</h6>
          </div>
          <div class="text-center mt-2">
            <p>Choose a payment method</p>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="makePayment(loanPlan.subscription)">
              Card
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('Redge Account')">
              Redge Wallet
            </button>

          </div>
        </div>
        <div v-else-if="paymentType==='Redge Account'">
          <form>
            <div class="form-group">
              <label class="form-label" for="debit-amount">Amount</label>
              <input class="form-control form-control-clicked" id="debit-amount" type="number" placeholder="20000" v-model="v$.amount.$model" >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <app-button @click="debitLoan" :classes="'my-1 btn-warning w-100 shadow'" :title="'confirm'" :loading="loaders().repayLoan" :disabled="loaders().repayLoan || v$.amount.$invalid" ></app-button>
          </form>
          
        </div>
        <div v-else>
          <success-notification 
            title="Paid" 
            message="You have successfully repaid a loan installment" 
            nextPageName="View Transaction"
            next="/transaction"
            @close="removeOverlay"
          ></success-notification>
        </div>
      </modal>

      
    </div>
  </div>
  
</template>

<script>
import { mapStatusBgColor, formatter, formatCurrency, mapStatusTextColor } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import AppButton from '@/components/common/form/AppButton.vue';
import Modal from '@/components/common/modal/Modal.vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'LoanList',
  props: ['title', 'id'],
  components: {
    AppButton,
    Modal,
    SuccessNotification
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      amount: { required, min: minLength(1) },
    }
  },
  data() {
    return {
      loanPlan: {},
      isSuccessful: false,
      activeLoanIdx: null,
      activeLoan: {},
      updatePriceInterval: null,
      priceList: {},
      defaultCoinLogo: require("../../../assets/images/common/1027.png"),
      amount: 0,
      paymentType: null
    }
  },
  mounted() {
    // if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    // }
  },
  watch: {
  },
  methods: {
    ...mapActions([
      "confirmFlutterPayment", "myAccount", "abortPlan", "repayLoan"
    ]),
    ...mapGetters([
      "userLoanPlans", "auth", "account", "loaders", "userProfile"
    ]),
    selectType(type) {
      this.paymentType = type
    },
    selectPayment(plan, loan) {
      this.loanPlan = plan
      this.activeLoan = loan
      this.amount = (loan.amount - loan.amount_paid) / plan.subscription.rate
    },
    // 
    showTerminateModal(loan) {
      this.loanPlan = loan;
      const terminateModal = document.getElementById('terminate');
      const _terminateModal = bootstrapBundleMin.Modal.getOrCreateInstance(terminateModal)
        _terminateModal.toggle();
    },
    removeOverlay() {
      const overlay = document.getElementsByClassName("modal-backdrop")[0];
      if ( overlay ) {
        overlay.classList.remove(...overlay.classList)
      }
    },
    getStatusColor(status) {
      return mapStatusBgColor(status)
    },
    getStatusTextColor(status) {
      return mapStatusTextColor(status)
    },
    getPlanBalance(plan) {
        return plan.subscription.loans.reduce( (t, loan) => t + ( loan.status === "PAID" ? parseFloat(loan.amount_paid) : 0), 0 )
    },
    getPlanSpent(plan) {
        return plan.subscription.loans.reduce( (t, loan) => t + ( loan.status !== "PAID" ? parseFloat(loan.amount_paid) : 0), 0)
    },
    setDefault (img) {
        console.log(img)
    },
    stopLoan() {
      if ( !confirm("Are you sure you want to terminate this loan plan?")) {
        return 
      }
      const id = this.loanPlan.subscription.id;
      this.abortPlan(id)
      const elem = document.getElementById("terminate-button-" + id);
      elem.disabled = true;
    },
    debitLoan() {
      const debt = (this.activeLoan.amount - this.activeLoan.amount_paid) / this.loanPlan.subscription.rate
      let amount = this.amount
      if ( this.amount > debt) {
        toaster.info(`You will be debited a value equal to your debit of ${debt}`)
        amount = debt
      }
      if ( amount <= 0 ) {
        toaster.info("Invalid loan amount");
        return
      }
      this.repayLoan({amount, id: this.activeLoan.id}).then(() => {
        this.amount = 0;
        this.paymentType = "Successful"
        toaster.success("Loan debt successfully repaid");
      })
    },
    makePayment(sub) {
      const self = this;
      const paymentData = {
        tx_ref: sub.reference,
        amount: sub.base_currency_amount + 50,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        meta: {
          'counsumer_id': self.auth().id,
          'consumer_mac': window.localStorage.getItem("uuid")
        },
        customer: {
          email: self.auth().email,
          name: self.auth().name
        },
        customizations: {
          title: 'Redge Loan Plan',
          description: ( sub.name ? sub.name.toLowerCase() : "" ) + " loan payment",
          logo: 'https://app.redge.ng/img/redge.3fd2356f.png'
        },
        // payment_plan: this.plan.reference,
        callback: self.makePaymentCallback,
        onclose: self.closedPaymentModal
      }
      if ( self.auth().profile && self.auth().profile.phone ) {
        paymentData.customer.phonenumber = self.auth().profile.phone
      }
      self.$launchFlutterwave(paymentData)
    },
    makePaymentCallback(response) {
      console.log("Pay", response)
      const self = this;
      this.confirmFlutterPayment(response).then( res => {
        self.isSuccessful = true;
        self.$router.push({
          path: "/loan-subscribe-success"
        });
        
      });
    },
    closedPaymentModal() {
      if ( this.isSuccessful ) {
        this.$router.push({
          path: "/loan-subscribe-success"
        });
      }
    },
  },
  created() {
    this.moment = moment;
    this.formatter = formatter;
    this.formatCurrency = formatCurrency
  },
  unmounted() {
    if (this.updatePriceInterval) {
      clearInterval(this.updatePriceInterval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordion-body {
  padding: 1rem 0px;
}
.tb-bg-ACTIVE {
    background-color:rgba(34, 174, 229, 0.061);
}
/* .tb-bg-DECLINED {
    background-color:rgba(229, 34, 44, 0.061);
} */
.tb-bg-FAILED {
    background-color:rgba(229, 34, 44, 0.061);
}
.tb-bg-COMPLETED {
    background-color:rgba(34, 229, 96, 0.061);
}
.tb-bg-INITIATED {
    background-color:rgba(34, 73, 229, 0.061);
}
.accordion-style-two .accordion-item h6 {
    padding: 0 !important;
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
