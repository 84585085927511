const AppGetter = {
  activeFooterNav: state => state.activeFooterNav,
  toast: s => s.toast,
  appConfig: s => { 
    const config = localStorage.getItem("appConfig") 
    return config !== null ? JSON.parse(config) : s.appConfig
  },
  tenant: s => s.tenant
};

export default AppGetter;
