<template>
  <div class="container mt-2 mb-4">
    <div class="card">
      <div class="card-header">
          <h6 class="py-1 font-weight-bold fw-bold"><i class="pi pi-sparkles"></i> Savings History</h6>
      </div>
      <div class="card-body" v-if="userSavingPlans() && userSavingPlans().length > 0">
        <div class="accordion accordion-flush accordion-style-two" id="accordionStyle2">
          <!-- Single Accordion -->
          <div class="accordion-item" v-for="(plan, i) in userSavingPlans()" :key="i">
            <div class="accordion-header" :id="`accordion${i}`" @click="getOtherPlans(plan)">
              <div class="mt-2 d-flex justify-content-between">
                <span>{{ plan.subscription.name + " (" + plan.name + ")" }}</span>
                <h6 
                  data-bs-toggle="collapse" 
                  :data-bs-target="`#accordionStyleCol${i}`" 
                  aria-expanded="false" 
                  :aria-controls="`accordionStyleCol${i}`" 
                  class="collapsed"
                  style="display:block;width:1.5rem;padding:0px;"
                >
                  <i class="bi bi-plus-lg"></i>
                </h6>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <span>
                  {{ formatCurrency({amount: getPlanBalance(plan), currency: plan.subscription.currency}) }}
                </span>
                <p class="text-sm text-muted">
                  <small>
                    {{ moment(plan.subscription.updated_at || plan.subscription.updated_at).format("DD MMM, HH:mm") }}
                  </small>
                  &nbsp;
                  <span class="badge rounded-pill" :class="`${getStatusColor(plan.subscription.status)}`">{{ plan.subscription.status?.toLowerCase() }}</span>
                </p>
              </div>
            </div>

            <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle2" style="">
              <div class="accordion-body">
                  
                <p class="mb-0">{{ plan.subscription.comment }}</p>
                <h4 class="badge bg-dark">
                    {{ plan.subscription.base_currency }} {{ plan.subscription.base_currency_amount.toFixed(2) }} 
                    | {{ plan.duration_unit === "day" ? "daily" : plan.duration_unit + "ly" }} savings
                    <!-- {{ plan.subscription.base_currency }} {{( plan.subscription.rate * plan.subscription.amount).toFixed(2) }} -->
                </h4>
                <!-- <p>{{ plan.subscription.currency }} rate: {{ plan.subscription.rate }} {{ plan.subscription.base_currency }}</p> -->
                <p>
                  Purpose: {{ plan.subscription.name || "" }} <br/> 
                  Saved: <b>{{ formatCurrency({amount: getPlanBalance(plan), currency: plan.subscription.currency}) }}</b> <br/>
                  Goal: {{ formatCurrency({currency: plan.subscription.currency, amount: getPlanSpent(plan)}) }} <br/>
                  Duration: {{ plan.subscription.duration }} {{ plan.subscription.duration_unit }}  <br/>
                  Funding Source: {{ plan.subscription.funding_source }}
                </p>
                
                <div v-if="plan.is_group && plan.subscription.created_by === auth().id">
                  <div class="mt-1 d-flex justify-content-between">
                    <span>
                      Plan Code: <b> {{ plan.subscription.code }}</b>
                    </span>
                    <button 
                    :id="`add-user-${plan.subscription.id}`"
                    class="btn btn-warning btn-sm btn-creative" 
                    data-bs-toggle="modal" 
                    data-bs-target="#add-plan-user" 
                    >users</button>
                  </div>
                </div>

                <div v-if="loaders().fetchByReference">
                  <div class="d-flex flex-column justify-content-center text-center">
                    <div class="my-4 text-center">loading users</div>
                    <br/>
                    <div class="mb-4 text-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="plan.is_group" class="mt-2">
                  <div 
                    v-for="(group, k) in referencePlans[plan.subscription.reference]" 
                    :key="k" 
                    class="single-plan-check shadow-sm active-effect d-block installments"
                    :class="{'tb-bg-COMPLETED': group.status === 'COMPLETED'}"
                  >
                    <div class="w-full d-flex justify-content-between" @click="toggleShowInstallment(k)">
                      <span>{{ group.user.name }} <i v-if="currentCollector === group.id" class="bi bi-star-fill text-warning"></i></span>
                      <small class="text-xs text-muted"> &nbsp; {{
                        (group.plan.strategy === 'linear' && group.savings && group.savings[k]) ? 
                        moment(group.savings[k].due_at).format("DD MMM HH:mm a") : group.user.email 
                        }}
                      </small>
                    </div>
                    <transition name="bounce">
                      <div class="mt-1" v-if="showUserInstallment === k">
                        <hr/>
                        <p>Payment breakdown: </p>
                        <p class="mb-0" v-if="group.savings">
                        
                          <table class="table mb-0 table-hover" >
                            <thead>
                                <th class="text-center">
                                    Amount
                                </th>
                                <th class="text-center">
                                    status
                                </th>
                                <th class="text-center">
                                  {{ group.plan.strategy === 'linear' ? 'Paid By' : 'Due At' }}
                                </th>
                            </thead>
                            <tbody>
                              <tr 
                                v-for="(grpSaving, m) in group.savings" 
                                :key="m" 
                                :class="`tb-bg-${grpSaving.status}`" 
                                :title="grpSaving.status" 
                                @mouseover="activeSaveIdx=m; selectPayment(plan, grpSaving)" 
                                @mouseout="activeSaveIdx=null"
                              >
                                <td>
                                  <div class="text-xs" :class="{'blur': plan.subscription.created_by !== auth().id && group.user.id !== auth().id && group.plan.strategy !== 'linear' }">
                                    {{ assignCurrenctCollector(grpSaving) }}
                                    {{ group.currency }} {{ Math.round(grpSaving.amount * 10)/10 }} 
                                  </div>
                                </td>
                                <td>
                                  <div class="text-xs">
                                    {{ grpSaving.status }}
                                  </div>
                                </td>
                                <td  v-if="group.plan.strategy === 'linear'">
                                  <div class="text-xs">
                                    <button 
                                      v-if="['PENDING', 'DUE'].includes(grpSaving.status) && grpSaving.plan_user_id === currentCollector"
                                      class="btn btn-sm btn-success"
                                      :id="`pay-button-grp-${grpSaving.id}`"
                                      data-bs-toggle="modal" 
                                      data-bs-target="#pay-savings" 
                                    >pay!</button>
                                    <div v-else-if="['PAID', 'WITHDRAWN'].includes(grpSaving.status)">
                                      {{ grpSaving.payer.name }}
                                    </div>
                                    <div v-else>
                                      <i class="bg-gray-500 xs">awaiting...</i>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center" v-else>
                                  <div class="text-xs" v-if="activeSaveIdx==m && ['PENDING', 'DUE'].includes(grpSaving.status)" >
                                    <button 
                                      v-if="plan.subscription.created_by === auth().id || group.user.id === auth().id"
                                      class="btn btn-sm btn-success"
                                      :disabled="moment(grpSaving.due_at).startOf('day').isAfter(new Date)" 
                                      :id="`pay-button-grp-${grpSaving.id}`"
                                      data-bs-toggle="modal" 
                                      data-bs-target="#pay-savings" 
                                    >pay!</button>
                                    <button 
                                      v-else
                                      class="btn btn-sm btn-success"
                                      :disabled="moment(grpSaving.due_at).startOf('day').isAfter(new Date)" 
                                      :id="`nudge-button-grp-${grpSaving.id}`" 
                                    >nudge!</button>
                                  </div>
                                  <div v-else>
                                    {{ moment(grpSaving.due_at).format("DD MMM HH:mm a") }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </p>
                        <br/>
                        <div class="mt-1 text-end">
                          <button 
                          :id="`terminate-group-button-${group.id}`"
                          class="btn btn-warning btn-sm btn-creative" 
                          data-bs-toggle="modal" 
                          data-bs-target="#terminate" 
                          @click="() => {savingPlan = group; stage = stages.TERMINATE}" 
                          v-if="(group.status ==='ACTIVE' || group.status ==='INITIATED') && group.created_by === auth().id" 
                          :disabled="group.status!=='ACTIVE' && group.status !=='INITIATED'">terminate</button>
                        </div>
                        
                      </div>
                    </transition>
                  </div>
                </div>

                <div v-else>
                  <p>Savings plan: </p>
                  <p class="mb-0" v-if="plan.subscription.savings">   
                    <table class="table mb-0 table-hover" >
                        <thead>
                            <th class="text-center">
                                Amount
                            </th>
                            <th class="text-center">
                                status
                            </th>
                            <th class="text-center">
                                Due At
                            </th>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="(saving, j) in plan.subscription.savings" 
                                :key="j" 
                                :class="`tb-bg-${saving.status}`" 
                                :title="saving.status" 
                                @mouseover="activeSaveIdx=j; selectPayment(plan, saving)" 
                                @mouseout="activeSaveIdx=null"
                            >
                                <td>
                                    <div class="text-xs">
                                        <!-- {{ formatCurrency({amount: saving.amount, currncy: plan.subscription.currency}) }} -->
                                        {{ plan.subscription.currency }} {{ Math.round(saving.amount * 10)/10 }} 
                                    </div>
                                </td>
                                <td>
                                    <div class="text-xs">
                                        {{ saving.status }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="text-xs" v-if="activeSaveIdx==j && ['PENDING', 'DUE'].includes(saving.status)" >
                                      <button 
                                        class="btn btn-sm btn-success" 
                                        :disabled="moment(saving.due_at).isAfter(new Date)" 
                                        :id="`pay-button-${saving.id}`"
                                        data-bs-toggle="modal" 
                                        data-bs-target="#pay-savings" 
                                      >pay!</button>
                                    </div>
                                    <div v-else>
                                        {{ moment(saving.due_at).format("DD MMM HH:mm a") }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </p>
                  <br/>
                  <div class="mt-1 text-end">
                    <button 
                    :id="`terminate-button-${plan.subscription.id}`"
                    class="btn btn-warning btn-sm btn-creative" 
                    data-bs-toggle="modal" 
                    data-bs-target="#terminate" 
                    @click="() => {savingPlan = plan; stage = stages.TERMINATE}" 
                    v-if="plan.subscription.status ==='ACTIVE' || plan.subscription.status ==='INITIATED'" 
                    :disabled="plan.subscription.status!=='ACTIVE' && plan.subscription.status !=='INITIATED'">terminate</button>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
          <div class="text-center">
              <i class="bi bi-clipboard-x"></i>
              Stop loosing money <br/>
              <small>
                <router-link to="/saves" style="text-decoration: none; color: var(--jw-text-success)"> Save now. </router-link>
              </small>
              <img src="../../../assets/images/saving/empty-save.png" />
          </div>
      </div>
    </div>

    <modal title="Terminate Saving" id="terminate">
      <div v-if="stage===stages.TERMINATE">
        <h6>
          You are about to terminate your savings plan "<b>{{ savingPlan.subscription ? savingPlan.subscription.name : savingPlan.name}}</b>"
        </h6>
        <i class="bi bi-clock"></i> Due date: 
        <b v-if="savingPlan.subscription">
          {{ moment(savingPlan.subscription.savings[savingPlan.subscription.savings.length - 1].due_at).format("DD MMM HH:mm a") }}
        </b>
        <b v-else>{{ moment(savingPlan.savings[savingPlan.savings.length - 1].due_at).format("DD MMM HH:mm a") }}</b>

        <div v-if="savingPlan.is_flexible 
        || ( savingPlan.subscription && moment(savingPlan.subscription.savings[savingPlan.subscription.savings.length - 1].due_at).isBefore(new Date))
        || ( savingPlan.savings && moment(savingPlan.savings[savingPlan.savings.length - 1].due_at).isBefore(new Date))
        ">
          <i class="bi bi-cash-coin"></i> Termination Fee: 0
        </div>
        <div v-else>
          <i class="bi bi-cash-coin"></i> Termination before the due date will cost you <b>{{ savingPlan.opt_out_penalty }}%</b> of your saved amount.
          <span></span>
        </div>
        <div class="text-end mt-2">
          <app-button @click="stopSavings()" :classes="'btn-sm btn-danger'" :title="'terminate'" :loading="loaders().abortPlan" :disabled="loaders().abortPlan" ></app-button>
        </div>
      </div>
      <div v-else-if="stage===stages.TERMINATED">
        <success-notification 
          title="Successful" 
          message="Savings Terminated!" 
          nextPageName="Done"
          @close="stage=''"
          to="/transaction"
        ></success-notification>
      </div>
      
    </modal>

    <modal id="add-plan-user">
      <div  v-if="stage === ''">
        <h6>Savings User List </h6>
        <div v-if="Object.values(savingPlan).length > 0">
          <div v-for="(plan, i) in referencePlans[savingPlan.subscription ? savingPlan.subscription.reference : savingPlan.reference]" :key="i" class="single-plan-check shadow-sm active-effect">
            <span>
              {{ plan.user.name }} <small> &nbsp; ({{ plan.user.name }})</small>
            </span>
          </div>
        </div>
        <div v-else>
          No user in this plan yet!
        </div>
        <hr/>
        <div class="mt-1 text-end">
          <button 
          class="btn btn-warning btn-sm btn-creative" 
          @click="stage = stages.SELECT_USER"
          >Add User</button>
        </div>
      </div>
      <div v-if="stage === stages.SELECT_USER">
        <add-user :stopStage="stages.PREVIEW" :referrer="auth().settings?.referrer_code" @user="previewUserAddition"></add-user>
      </div>
      <div v-if="stage === stages.PREVIEW">
        <h6>
            Preview and Confirm
            <br>
            <small>Add user to plan</small>
        </h6>
        <div>
            <b>User: </b>{{ selectedUser.name }} ({{ selectedUser.email }})
            <br/>
            <b>Plan: </b>{{ savingPlan.subscription.name + " (" + savingPlan.name + ")" }}
        </div>
        <div class="form-group">
          <label class="form-label" for="debit-amount"><b>Amount ({{ savingPlan.subscription.base_currency }})</b></label>
          <input class="form-control form-control-clicked" id="debit-amount" type="number" placeholder="20000" v-model="v$.amount.$model" >
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="text-center my-1 mb-8">
            <app-button 
            @click="addUserToPlan" 
            :title="'Confirm'"
            :disabled="loaders().joinPlan || v$.amount.$invalid"
            :loading="loaders().joinPlan"
            ></app-button>
        </div>
        <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
          <div class="error-msg">{{ key }}: {{ error }}</div>
        </div>
        <hr/>
        <div @click="stage=stages.SELECT_USER">back</div>
      </div>
      <success-notification 
        v-if="stage === stages.COMPLETED"
        title="Successful" 
        message="Thank You!" 
        nextPageName="Done"
        @close="stage=''"
      ></success-notification>
    </modal>

    <modal title="Pay Savings" id="pay-savings" @close="paymentType=null">
      <div v-if="!paymentType">
        <div class="text-center"> 
          <h6>Payment method</h6>
        </div>
        <div class="text-center mt-2">
          <p>Choose a payment method</p>
          <button class="btn m-1 btn-sm btn-warning shadow" @click="makePayment(savingPlan.subscription)">
            Card
          </button>
          <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('Redge Account')">
            Redge Wallet
          </button>

        </div>
      </div>
      <div v-else-if="paymentType==='Redge Account'">
        <form>
          <div class="form-group">
            <label class="form-label" for="debit-amount">Amount</label>
            <input class="form-control form-control-clicked" id="debit-amount" type="number" placeholder="20000" v-model="v$.amount.$model" >
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <app-button @click="payForSavings" :classes="'my-1 btn-warning w-100 shadow'" :title="'confirm'" :loading="loaders().paySavings" :disabled="loaders().paySavings || v$.amount.$invalid" ></app-button>
        </form>
        
      </div>
      <div v-else>
        <success-notification 
          title="Paid" 
          message="You have successfully paid this savings installment" 
          nextPageName="Done"
          @close="removeOverlay"
        ></success-notification>
      </div>
    </modal>

  </div>
</template>

<script>
import { mapStatusBgColor, mapStatusTextColor, formatCurrency } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import AppButton from '@/components/common/form/AppButton.vue';
import Modal from '@/components/common/modal/Modal.vue'
import AddUser from '@/components/common/user/AddUser.vue'
import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'SaveList',
  props: ['title', 'id'],
  components: {
    AppButton,
    Modal,
    AddUser,
    SuccessNotification
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      amount: { required, min: minLength(1) },
    }
  },
  data() {
    return {
      savingPlan: {},
      isSuccessful: false,
      activeSaveIdx: null,
      activeSave: null,
      updatePriceInterval: null,
      priceList: {},
      defaultCoinLogo: require("../../../assets/images/common/1027.png"),
      selectedUser: {},
      stage: "",
      stages: {
        SELECT_USER: 'select-user',
        PREVIEW: 'preview',
        COMPLETED: 'completed',
        TERMINATE: 'terminate',
        TERMINATED: 'terminated'
      },
      referencePlans: {},
      errors: {},
      showUserInstallment: 0,
      amount: 0,
      paymentType: null,
      currentCollector: null
    }
  },
  mounted() {
    // if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    // }
  },
  watch: {
  },
  methods: {
    ...mapActions([
        "confirmFlutterPayment", 
        "myAccount", 
        "abortPlan", 
        "joinPlan", 
        "paySavings",
        "fetchByReference"
    ]),
    ...mapGetters([
      "userSavingPlans", "auth", "account", "loaders"
    ]),
    getStatusColor(status) {
      return mapStatusBgColor(status)
    },
    getStatusTextColor(status) {
      return mapStatusTextColor(status)
    },
    assignCurrenctCollector(savings) {
      if (this.currentCollector !== null) {
        return
      }
      if (["PENDING", "DUE"].includes(savings.status)) {
        this.currentCollector = savings.plan_user_id
      }
    },
    getPlanBalance(plan) {
        return plan.subscription.savings.reduce( (t, saving) => t + ( saving.status === "PAID" ? parseFloat(saving.amount) : 0), 0 )
    },
    getPlanSpent(plan) {
        return plan.subscription.savings.reduce( (t, saving) => t + parseFloat(saving.amount), 0 )
    },
    setDefault (img) {
        console.log(img)
    },
    selectType(type) {
      this.paymentType = type
    },
    selectPayment(plan, saving) {
      this.savingPlan = plan
      this.activeSave = saving
      this.amount = (saving.amount - saving.amount_paid) / plan.subscription.rate
    },
    stopSavings(){
      if ( !confirm("Are you sure you want to stop this savings plan?")) {
        return 
      }
      const self = this;
      const id = this.savingPlan.subscription ? this.savingPlan.subscription.id : this.savingPlan.id;
      this.abortPlan(id).then(() => {
        self.stage = self.stages.TERMINATED
        toaster.success("Saving terminated");
      }).catch( e => {
        toaster.error(e.toString());
      }).finally(() => {

      })
      // const elem = document.getElementById("terminate-button-" + id);
      // elem.disabled = true;
    },
    payForSavings() {
      const debt = (this.activeSave.amount - this.activeSave.amount_paid) / this.savingPlan.subscription.rate
      let amount = this.amount
      if ( this.amount > debt) {
        toaster.info(`You will be debited a value equal to your debit of ${debt}`)
        amount = debt
      }
      if ( amount <= 0 ) {
        toaster.info("Invalid savings amount");
        return
      }
      this.paySavings({amount, id: this.activeSave.id}).then(() => {
        this.amount = 0;
        this.paymentType = "Successful"
        this.updateSavingStatus(this.activeSave.id, "PAID")
        toaster.success("Savings successfully paid");
      })
    },
    updateSavingStatus(id, status) {
      const reference = this.savingPlan.subscription ? this.savingPlan.subscription.reference: this.savingPlan.reference;
      const plans = ( this.savingPlan.is_group) ? this.referencePlans[reference] : null
      const plan = plans[this.showUserInstallment];

      if (plan === null) {
        return
      }

      for ( const i in plan.savings ) {
        const saving = plan.savings[i];
        if ( saving.id === id) {
          plans[this.showUserInstallment].savings[i] = {
            ...plans[this.showUserInstallment].savings[i],
            status,
            payer: this.auth()
          }
          break;
        }
      }

      this.referencePlans[reference] = plans
      
    },
    makePayment(sub) {
      const self = this;
      const paymentData = {
        tx_ref: sub.reference,
        amount: sub.base_currency_amount + 50,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        meta: {
          'counsumer_id': self.auth().id,
          'consumer_mac': window.localStorage.getItem("uuid")
        },
        customer: {
          email: self.auth().email,
          name: self.auth().name
        },
        customizations: {
          title: 'Redge Savings Plan',
          description: ( sub.name ? sub.name.toLowerCase() : "" ) + " saving payment",
          logo: 'https://app.redge.ng/img/redge.3fd2356f.png'
        },
        // payment_plan: this.plan.reference,
        callback: self.makePaymentCallback,
        onclose: self.closedPaymentModal
      }
      if ( self.auth().profile && self.auth().profile.phone ) {
        paymentData.customer.phonenumber = self.auth().profile.phone
      }
      self.$launchFlutterwave(paymentData)
    },
    makePaymentCallback(response) {
      console.log("Pay", response)
      const self = this;
      this.confirmFlutterPayment(response).then( res => {
        self.isSuccessful = true;
        self.$router.push({
          path: "/save-subscribe-success"
        });
        
      });
    },
    closedPaymentModal() {
      if ( this.isSuccessful ) {
        this.$router.push({
          path: "/save-subscribe-success"
        });
      }
    },
    previewUserAddition(user) {
      this.selectedUser = user
      this.stage = this.stages.PREVIEW
    },
    addUserToPlan() {
      if ( !this.savingPlan.subscription.code ) {
        this.errors = {message: "You cannot subscribe a user to this plan"}
        return
      }
      if ( this.amount < 0 ) {
        this.errors = {message: "Invalid savings amount"}
        return
      }

      this.joinPlan({
        user: this.selectedUser.id, 
        code: this.savingPlan.subscription.code,
        amount: this.amount
      }).then (r => {
        this.stage = this.stages.COMPLETED;
        const reference = this.savingPlan.subscription ? this.savingPlan.subscription.reference : this.savingPlan.reference;
        this.referencePlans[reference].push(r)

      }).catch( e => {
        const { message } = e
        this.errors = { message }
      });
    },
    getOtherPlans(plan) {
      if ( !plan.is_group ) {
        return
      }
      this.currentCollector = null
      const self = this
      this.savingPlan = plan;
      const reference = plan.subscription.reference
      this.amount = Math.ceil(plan.subscription.amount / plan.subscription.rate)
      if ( self.referencePlans[reference] ) {
        return
      }
      this.fetchByReference(reference).then( res => {
        self.referencePlans[reference] = res
      })
    },
    toggleShowInstallment(k) {
      this.showUserInstallment = this.showUserInstallment === null ? k : null
    }
  },
  created() {
    this.moment = moment;
    this.formatCurrency = formatCurrency
  },
  unmounted() {
    if (this.updatePriceInterval) {
      clearInterval(this.updatePriceInterval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordion-body {
  padding: 1rem 0px;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.installments::hover {
  background-color: #eee;
}
.tb-bg-ACTIVE {
    background-color:rgba(34, 174, 229, 0.061);
}
/* .tb-bg-DECLINED {
    background-color:rgba(229, 34, 44, 0.061);
} */
.tb-bg-FAILED {
    background-color:rgba(229, 34, 44, 0.061);
}
.tb-bg-COMPLETED {
    background-color:rgba(34, 229, 96, 0.061);
}
.tb-bg-INITIATED {
    background-color:rgba(34, 73, 229, 0.061);
}
.accordion-style-two .accordion-item h6 {
    padding: 0 !important;
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
