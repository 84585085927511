import http from "./http";

export const GetEmails = async(_q) =>
    await http.get("/notification/email" + _q);

export const GetSmS = async(_q) =>
    await http.get("/notification/sms" + _q);

export const SendEmail = async(notification) =>
    await http.post("/notification/email", notification);

export const SendSmS = async(notification) =>
    await http.post("/notification/sms", notification);

// export const SaveAds = async (ads) => {
//   http.defaults.headers = {
//     "Content-Type": "multipart/form-data",
//     "x-auth-id": "assets",
//     Accept: "*/*"
//   };
//   const res = await http.post("/ads", ads);
//   http.defaults.headers = {
//     "Content-Type": "application/json",
//     Accept: "application/json"
//   };

//   return res;
// };

export const SubscribeForAlert = async(user_uuid) =>
    await http.post("/alert/subscribe", { user_uuid });

export const SubscribeForData = async(user_uuid) =>
    await http.post("/vend-data/subscribe", { user_uuid });