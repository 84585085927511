<template>
  <div v-if="walletTransactionPinToUpdate.length > 0" class="container">
    <div class="alert custom-alert-3 alert-warning alert-dismissible fade show">
      <i class="bi bi-exclamation-circle"></i>
      <div class="alert-text">
        <h6>Transaction Pin!</h6>
        <span>
          Kindly consider reseting your wallet transaction pin in order to secure your wallet and spend with ease.
          <br/>
          <div class="d-flex justify-content-end">
            <router-link 
              v-for="(currency, i) in walletTransactionPinToUpdate" :key="i" 
              class="btn btn-sm btn-creative btn-warning mt-2 shadow" 
              :to="{ path: 'settings', query: { currency, operation: 'reset-pin' }}"
              >
                Set {{ currency }} Wallet Pin
            </router-link>
          </div>
        </span>
      </div>
      <button class="btn btn-close position-relative p-1 ms-auto" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
      
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TransactionPin',
  components: {
  },
  data() {
    return {
      walletTransactionPinToUpdate: []
    }
  },
  mounted() {
    const self = this;
    this.transactionPinStatus().then( res => {
      self.walletTransactionPinToUpdate = Object.keys(res).filter( v => res[v] !== 'pin_set')
    })
  },
  methods: {
    ...mapActions([
      "transactionPinStatus",
    ]),
    ...mapGetters([
      "loaders",
      "walletPinStatus"
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
