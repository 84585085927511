import {
    SendEmail,
    SendSmS,
    SubscribeForAlert,
    SubscribeForData
} from "@/services/notification";

export const sendEmail = (context, notification) => {
    const data = {
        body: notification.body,
        to: notification.to
    };
    if (notification.from) {
        data.from = notification.from;
    }
    if (notification.recipients) {
        data.recipients = notification.recipients;
    }
    if (notification.subject) {
        data.subject = notification.subject
    }

    context.commit("updateLoader", { sendEmail: true });
    SendEmail(data)
        .then(res => {
            context.commit("sendEmail", res.data.data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { sendEmail: false });
        });
};

export const sendSmS = (context, notification) => {
    const data = {
        body: notification.body,
        to: notification.to
    };
    if (notification.from) {
        data.from = notification.from;
    }
    if (notification.recipients) {
        data.recipients = notification.recipients;
    }
    context.commit("updateLoader", { sendSmS: true });
    SendSmS(data)
        .then(res => {
            context.commit("sendSmS", res.data.data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { sendSmS: false });
        });
};

export const subscribeForAlert = (context, user_uuid) => {

    context.commit("updateLoader", { subscribeForAlert: true });
    SubscribeForAlert(user_uuid)
        .then(res => {
            context.commit("fetchAuth", res.data.data);
            resolve(res.data.data)
        })
        .catch(err => {
            reject(err)
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { subscribeForAlert: false });
        });
};

export const subscribeForData = (context, user_uuid) => {

    context.commit("updateLoader", { subscribeForData: true });
    SubscribeForData(user_uuid)
        .then(res => {
            context.commit("fetchAuth", res.data.data);
            resolve(res.data.data)
        })
        .catch(err => {
            reject(err)
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { subscribeForData: false });
        });
};

const NotificationAction = {
    sendEmail,
    sendSmS,
    subscribeForAlert,
    subscribeForData
};

export default NotificationAction;