const PlanGetter = {
  savingPlans: state => state.savingPlans,
  loanPlans: state => state.loanPlans,
  investmentPlans: state => state.investmentPlans,
  subscribe: state => state.subscribe,
  userInvestmentPlans: state => state.userInvestmentPlans,
  userSavingPlans: state => state.userSavingPlans,
  userLoanPlans: state => state.userLoanPlans
};

export default PlanGetter;
