<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-4">
            <h6 class="mb-3 text-white">Value Add Services.</h6>
            <router-link class="btn btn-warning btn-sm" to="/dashboard"><i class="pi pi-home"></i> Home</router-link>
          </div>
        </div>
      </div>


      <div class="notification-area mt-4">
        <div class="container">
          <div class="card">
            <div class="card-body">
              <div class="standard-tab">
                <ul class="nav rounded-lg mb-2 p-2 shadow-sm" id="affanTabs1" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button :class="`btn ${page === 'airtime' ? 'active': ''} btn-sm`" id="airtime-tab" data-bs-toggle="tab" data-bs-target="#airtime" type="button" role="tab" aria-controls="airtime" aria-selected="false" tabindex="-1">
                      <i class="pi pi-phone" style="font-size: 10px;"></i> Airtime
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button :class="`btn ${page === 'data' ? 'active': ''} btn-sm`" id="data-tab" data-bs-toggle="tab" data-bs-target="#data" type="button" role="tab" aria-controls="data" aria-selected="false" tabindex="-1">
                      <i class="pi pi-wifi" style="font-size: 10px;"></i> Data
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button :class="`btn ${page === 'cable' ? 'active': ''} btn-sm`" id="cable-tab" data-bs-toggle="tab" data-bs-target="#cable" type="button" role="tab" aria-controls="cable" aria-selected="true">
                      <i class="pi pi-desktop" style="font-size: 10px;"></i> Cable
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button :class="`btn ${page === 'power' ? 'active': ''} btn-sm`" id="power-tab" data-bs-toggle="tab" data-bs-target="#power" type="button" role="tab" aria-controls="power" aria-selected="true">
                      <i class="pi pi-mobile" style="font-size: 10px;"></i> Power
                    </button>
                  </li>
                </ul>

                <div class="tab-content rounded-lg p-3 shadow-sm" id="affanTabs1Content">
                  <div :class="`tab-pane fade ${page === 'airtime' ? 'active show': ''}`" id="airtime" role="tabpanel" aria-labelledby="airtime-tab">
                    <h6><i class="pi pi-sparkle"></i> Airtime Top Up</h6>
                    <p class="mb-4">Buy airtime directly from your redge wallet.</p>
                    <form>
                      <div>
                        <div class="form-group">
                          <label class="form-label" for="airtime-network">Select Network</label>
                          <ul class="box-service mt-3">
                            <li v-for="(ips, i) in ['mtn', 'airtel', 'glo', 'etisalat']" :key="i">
                              <label class="stretched-link">
                                <div class="icon-box bg_color_1" :class="{
                                  'selected': airtime.network === ips
                                }">
                                  <img :src="logos[ips]" style="width: 26px; border-radius: 50%;" />
                                </div>
                                <input 
                                  type="radio" 
                                  name="airtime-network" 
                                  :value="ips" 
                                  id="airtime-network" 
                                  :disabled="loaders().vendAirtime"
                                  v-model="v$.airtime.network.$model"
                                  style="display: none;"
                                />
                                <span>{{ ips }}</span>
                              </label>
                            </li>
                          </ul>
                          <!-- <select class="form-select" id="airtime-network-2" name="airtime-network" :disabled="loaders().vendAirtime" aria-label="airtime-network" v-model="v$.airtime.network.$model" >
                            <option v-for="(ips, i) in ['mtn', 'airtel', 'glo', 'etisalat']" :key="i" :value="ips">{{ ips }}</option>
                          </select> -->
                        </div>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.airtime.network.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <label class="form-label" for="airtime-network">Phone</label>
                        <div class="input-group mb-3">
                          <input class="form-control" type="text" aria-label="Text input with checkbox"  v-model="v$.airtime.phone.$model" placeholder="080123456789" :disabled="loaders().vendAirtime">
                          <div class="input-group-text">
                            <input class="form-check-input" v-model="usePhone" id="airtime-number-number" type="checkbox" aria-label="aitime-number"> &nbsp; 
                            <label class="form-check-label" for="airtime-number-number">self</label>
                          </div>
                        </div>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.airtime.phone.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="airtime-network">Amount</label>
                        <div class="d-flex">
                          <div 
                            class="cursor rounded px-4 py-2 border mb-1"
                            style="margin-right: 0.25rem;"
                            :class="{
                              'bg-success border-success text-white': airtime.amount === amount
                            }"
                            v-for="(amount, j) in [100, 200, 500]"
                            :key="j"
                            @click="airtime.amount = amount"
                          >{{ amount }}</div>
                        </div>
                        <input class="form-control" type="number" aria-label="airtime-amount" :disabled="loaders().vendAirtime" v-model="v$.airtime.amount.$model" placeholder="500" />
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.airtime.amount.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" id="confirm-airtime" type="checkbox" v-model="v$.airtime.confirm.$model">
                        <label class="form-check-label" for="confirm-airtime">confirm action</label>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.airtime.confirm.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <app-button 
                          @click="authorizeFor('buyAirtime')"
                          data-bs-toggle="modal" 
                          data-bs-target="#auth-payment-modal" 
                          :classes="'my-1 btn-success w-100 shadow'" 
                          :title="'top up'" :loading="loaders().vendAirtime" 
                          :disabled="loaders().vendAirtime || v$.airtime.$invalid" 
                        ></app-button>
                      </div>

                    </form>
                  </div>

                  <div :class="`tab-pane fade ${page === 'data' ? 'active show': ''}`" id="data" role="tabpanel" aria-labelledby="data-tab">
                    <h6>Data Top Up</h6>
                    <p class="mb-4">Buy data directly from your redge wallet.</p>
                    <form>
                      <div class="form-group">
                        <label class="form-label" for="data-network">Select Network</label>

                        <ul class="box-service mt-3">
                          <li v-for="(ips, i) in ['mtn', 'airtel', 'glo', 'etisalat']" :key="i">
                            <label class="stretched-link">
                              <div class="icon-box bg_color_1" :class="{
                                'selected': data.network === ips
                              }">
                                <img :src="logos[ips]" style="width: 26px; border-radius: 50%;" />
                              </div>
                              <input 
                                type="radio" 
                                name="data-network" 
                                :value="ips" 
                                id="data-network" 
                                :disabled="loaders().vendData"
                                v-model="v$.data.network.$model"
                                style="display: none;"
                                @change="changeData"
                              />
                              <span>{{ ips }}</span>
                            </label>
                          </li>
                        </ul>
                        <!-- <select class="form-select" :disabled="loaders().fetchData || loaders().vendData" id="data-network" name="data-network" aria-label="data-network" v-model="v$.data.network.$model" @change="changeData">
                          <option v-for="(ips, i) in ['mtn', 'airtel', 'glo', 'etisalat']" :key="i" :value="ips" >{{ ips }}</option>
                        </select> -->
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.data.network.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <label class="form-label" for="data-network">Phone</label>
                        <div class="input-group mb-3">
                          <input class="form-control" :disabled="loaders().vendData" type="text" aria-label="Text input with checkbox" placeholder="080123456789" v-model="v$.data.phone.$model">
                          <div class="input-group-text">
                            <input class="form-check-input" v-model="usePhone" id="data-number-number" type="checkbox" aria-label="aitime-number"> &nbsp; 
                            <label class="form-check-label" for="data-number-number">self</label>
                          </div>
                        </div>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.data.phone.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="data-network">Package</label>
                        <!-- <select class="form-select" :disabled="loaders().fetchData || loaders().vendData" id="data-package" name="data-package" aria-label="data-package" v-model="v$.data.package.$model">
                          <option v-for="(_d, i) in vasdata()" :key="i" :value="_d">{{ _d.description }}</option>
                        </select> -->
                        <v-select 
                          :options="getDataOptions(vasdata())" 
                          label="name"
                          v-model="v$.data.package.$model"
                          :disabled="loaders().fetchData || loaders().vendData"
                          id="data-package" 
                          name="data-package"
                        ></v-select>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.data.package.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" id="confirm-data" type="checkbox" v-model="v$.data.confirm.$model">
                        <label class="form-check-label" for="confirm-data">confirm action</label>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.data.confirm.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <app-button
                          @click="authorizeFor('buyData')"
                          data-bs-toggle="modal" 
                          data-bs-target="#auth-payment-modal"
                          :classes="'my-1 btn-success w-100 shadow'" 
                          :title="'buy'" 
                          :loading="loaders().vendData" :disabled="loaders().vendData || v$.data.$invalid" 
                        ></app-button>
                      </div>

                    </form>
                  </div>

                  <div :class="`tab-pane fade ${page === 'cable' ? 'active show': ''}`" id="cable" role="tabpanel" aria-labelledby="cable-tab">
                    <h6>Cable TV</h6>
                    <p class="mb-4">Subscribe for cable tv directly from your redge wallet.</p>
                    <form>
                      <div class="form-group">
                        <label class="form-label" for="cable-network">Service</label>
                        <ul class="box-service mt-3">
                          <li v-for="(ips, i) in ['DSTV', 'GOTV', 'Startimes']" :key="i">
                            <label class="stretched-link">
                              <div class="icon-box bg_color_1" :class="{
                                'selected': cable.service === ips
                              }">
                                <img :src="logos[ips]" style="width: 26px; border-radius: 50%;" />
                              </div>
                              <input 
                                type="radio" 
                                name="cable-network" 
                                :value="ips" 
                                id="cable-network" 
                                :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable"
                                v-model="v$.cable.service.$model"
                                style="display: none;"
                                @change="changeCable"
                              />
                              <span>{{ ips }}</span>
                            </label>
                          </li>
                        </ul>

                        <!-- <select class="form-select" :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable" id="cable-network" name="cable-network" v-model="v$.cable.service.$model" aria-label="cable-network" @change="changeCable">
                          <option v-for="(ips, i) in ['DSTV', 'GOTV', 'Startimes']" :key="i" :value="ips">{{ ips }}</option>
                        </select> -->
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.service.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="cable-network">Package</label>
                        <!-- <select class="form-select" :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable" id="cable-package" v-model="v$.cable.code.$model" name="cable-package" aria-label="cable-package">
                          <option v-for="(_c, i) in vascable()" :key="i" :value="_c">{{ _c.description }}</option>
                        </select> -->
                        <v-select 
                          :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable"
                          :options="getCableOptions(vascable())" 
                          label="name"
                          v-model="v$.cable.code.$model"
                          id="cable-package" 
                          name="cable-package" 
                        ></v-select>

                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.code.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="cable-network">IUC Number</label>
                        <input class="form-control" :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable" type="text" v-model="v$.cable.iuc.$model" aria-label="IUC Number" placeholder="7000....." @keyup="validateCableData">
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.iuc.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="mb-2 text-dark">
                        {{ cableName }}
                      </div>

                      <div>
                        <label class="form-label" for="data-network">Phone (Optional)</label>
                        <div class="input-group mb-3">
                          <input class="form-control" :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable" type="text" aria-label="Text input with checkbox" placeholder="08012......" v-model="v$.cable.phone.$model">
                          <div class="input-group-text">
                            <input class="form-check-input" v-model="usePhone" id="cable-number-number" type="checkbox" aria-label="aitime-number"> &nbsp; 
                            <label class="form-check-label" for="cable-number-number">self</label>
                          </div>
                        </div>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.phone.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" id="confirm-cable" type="checkbox" v-model="v$.cable.confirm.$model">
                        <label class="form-check-label" for="confirm-cable">confirm action</label>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.confirm.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <app-button 
                        @click="authorizeFor('buyCable')"
                        data-bs-toggle="modal" 
                        data-bs-target="#auth-payment-modal" 
                        :classes="'my-1 btn-success w-100 shadow'" 
                        :title="'pay'" 
                        :loading="loaders().cable" :disabled="loaders().vendCable || loaders().fetchCable || v$.cable.$invalid" ></app-button>
                      </div>

                    </form>
                  </div>

                  <div :class="`tab-pane fade ${page === 'power' ? 'active show': ''}`" id="power" role="tabpanel" aria-labelledby="power-tab">
                    <h6>Buy Power</h6>
                    <p class="mb-4">Buy power directly from your redge wallet.</p>
                    <form v-if="Object.keys(powerData).length === 0 ">
                      <div class="form-group">
                        <label class="form-label" for="power-network">Type</label>
                        <select class="form-select" id="power-network" name="power-network" aria-label="power-network" v-model="v$.power.service.$model" :disabled="loaders().fetchPower || loaders().vendPower || loaders().validatePower" >
                          <option v-for="(_p, i) in vaspower()" :key="i" :value="_p">{{ _p.description }}</option>
                        </select>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.service.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="power-network">Meter Number</label>
                        <input class="form-control" type="text" aria-label="IUC Number" v-model="v$.power.meter.$model" @keyup="validatePowerData" placeholder="700..." :disabled="loaders().fetchPower || loaders().vendPower || loaders().validatePower" >
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.meter.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="mb-2 text-dark">
                        {{ powerName.name }}
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="power-network">Amount</label>
                        <input class="form-control" type="number" aria-label="power-amount" placeholder="5000" v-model="v$.power.amount.$model" :disabled="loaders().fetchPower || loaders().vendPower || loaders().validatePower" >
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.amount.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <label class="form-label" for="data-network">Phone (Optional)</label>
                        <div class="input-group mb-3">
                          <input class="form-control" :disabled="loaders().fetchPower || loaders().vendPower || loaders().validatePower" type="text" aria-label="Text input with checkbox" placeholder="08012......" v-model="v$.power.phone.$model">
                          <div class="input-group-text">
                            <input class="form-check-input" v-model="usePhone" id="power-number-number" type="checkbox" aria-label="aitime-number"> &nbsp; 
                            <label class="form-check-label" for="power-number-number">self</label>
                          </div>
                        </div>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.phone.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" id="confirm-power" type="checkbox" v-model="v$.power.confirm.$model">
                        <label class="form-check-label" for="confirm-power">confirm action</label>
                        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.confirm.$errors" :key="index">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                      </div>

                      <div>
                        <app-button 
                          @click="authorizeFor('buyPower')"
                          data-bs-toggle="modal" 
                          data-bs-target="#auth-payment-modal" 
                          :classes="'my-1 btn-success w-100 shadow'" 
                          :title="'pay'" 
                          :loading="loaders().vendPower" :disabled="loaders().vendPower || v$.power.$invalid" 
                        ></app-button>
                      </div>

                    </form>

                    <div v-else>
                      <table class="table mb-0 table-hover" >
                        <tbody>
                          <tr>
                            <td>amount</td>
                            <td>{{ power.amount }}</td>
                          </tr>
                          <tr>
                            <td>Token</td>
                            <td>{{ powerData.token }}</td>
                          </tr>
                          <tr>
                            <td>Unit</td>
                            <td>{{ powerData.units }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <a href="javascript:void(0)" @click="() => {powerData = {}; power = {}}" class="my-4">done</a> -->
                      <router-link to="/transaction">done</router-link>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal title="Authorize" id="auth-payment-modal">
        <verify-transaction-pin currency="NGN" @verified="authorizationCallback"></verify-transaction-pin>
      </modal>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import AppButton from '@/components/common/form/AppButton.vue';
import { createToaster } from "@meforma/vue-toaster";
import Modal from '@/components/common/modal/Modal.vue'
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import VerifyTransactionPin from '@/components/common/wallet/VerifyTransactionPin.vue';
const toaster = createToaster({ /* options */ });
import { formatter } from '@/utils';

export default {
  name: 'VasServices',
  components: {
    PageTemplate,
    AppButton,
    Modal,
    VerifyTransactionPin
  },
  watch: {
    // whenever question changes, this function will run
    usePhone: function (usePhone, oldUsePhone) {
      if ( usePhone ) {
        const profile = this.auth().profile
        this.airtime.phone = profile ? profile.phone : ""
        this.data.phone = profile ? profile.phone : ""
        this.cable.phone = profile ? profile.phone : ""
        this.power.phone = profile ? profile.phone : ""
      } else {
        this.airtime.phone = ""
        this.data.phone = ""
        this.cable.phone = ""
        this.power.phone = ""
      }
      
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      airtime: {
        network: { required },
        amount: { min: minLength(2) },
        phone: { required, min: minLength(11)},
        confirm: { required }
      },
      data: {
        network: { required },
        package: { required },
        phone: { required, min: minLength(11)},
        confirm: { required },
        amount: {}
      },
      cable: {
        service: { required },
        iuc: { required, min: minLength(10)},
        code: {},
        amount: {},
        phone: {},
        token: {},
        confirm: { required }
      },
      power: {
        service: { required },
        meter: { required, min: minLength(10)},
        amount: {min: minLength(2)},
        phone: {},
        token: {},
        confirm: { required }
      }
    }
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      usePhone: false,
      airtime: {},
      data: {},
      cable: {},
      power: {},
      cableName: "",
      powerName: "",
      powerData: {},
      page: "airtime",
      banner: banner || require("@/assets/images/common/22.jpeg"),
      payMethod: null,
      logos: {
        mtn: require("@/assets/images/services/mtn.png"),
        airtel: require("@/assets/images/services/airtel.png"),
        glo: require("@/assets/images/services/glo.png"),
        etisalat: require("@/assets/images/services/etisalat.png"),
        DSTV: require("@/assets/images/services/dstv.png"),
        GOTV: require("@/assets/images/services/gotv.png"),
        Startimes: require("@/assets/images/services/startimes.png"),
      }
    }
  },
  created() {
    this.formatter = formatter
  },
  mounted() {
    const { params, query } = this.$route;
    const data = query || params
    this.page = data.type || "airtime"
    this.fetchData("mtn")
    this.fetchCable("DSTV")
    this.fetchPower();
  },
  methods: {
    ...mapActions([
      "vendAirtime",
      "fetchData",
      "vendData",
      "fetchPower",
      "validatePower",
      "vendPower",
      "fetchCable",
      "validateCable",
      "vendCable"
    ]),
    ...mapGetters([
      "loaders",
      "vaspower",
      "vasdata",
      "vascable",
      "auth",
      "account",
      "appConfig"
    ]),
    buyAirtime: function() {
      if ( this.account().wallet.NGN.ledger_balance < this.airtime.amount ) {
        toaster.show("you cannot top up above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }
      delete this.airtime.confirm
      let self = this;
      this.vendAirtime(this.airtime).then( res => {
        self.$router.push({
          path: '/success', 
          // params: data,
          // query: data
        })
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    buyData: function() {
      delete this.data.confirm
      const dataPackage = this.data.package.code;
      const amount = this.data.package.amount
      const data = {
        ...this.data,
        package: dataPackage,
        amount
      }
      let self = this;
      this.vendData(data).then( res => {
        self.$router.push({
          path: '/success', 
          // params: data,
          // query: data
        })
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      });
    },
    validateCableData: function() {
      if ( !this.cable.iuc || this.cable.iuc.length < 10 ) {
        return;
      }
      const data = {
        service: this.cable.service,
        iuc: this.cable.iuc,
        code: this.cable.code.code,
        amount: this.cable.amount
      }
      const self = this;
      this.validateCable(data).then( res => {
        self.cable.token = res.code;
        self.cableName = res.description
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    validatePowerData: function() {
      if ( !this.power.meter || this.power.meter.length < 10 ) {
        return;
      }
      const data = {
        service: this.power.service.code,
        meter: this.power.meter,
        amount: this.power.amount
      }
      const self = this;
      this.validatePower(data).then( res => {
        self.power.token = res.code;
        if ( res.description.minimumAmount ) {
          self.power.service.min = res.description.minimumAmount
        }
        self.powerName = res.description
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    buyCable: function() {

      if ( this.account().wallet.NGN.ledger_balance < this.cable.amount ) {
        toaster.show("you cannot subscribe above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      const code = this.cable.code.code;
      const amount = this.cable.code.amount
      delete this.cable.confirm
      const data = {
        ...this.cable,
        code,
        amount
      }
      let self = this;
      this.vendCable(data).then( res => {
        self.$router.push({
          path: '/success', 
          // params: data,
          // query: data
        })
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      });
    },
    buyPower: function() {

      if ( this.account().wallet.NGN.ledger_balance < this.power.amount ) {
        toaster.show("you cannot buy power above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      const service = this.power.service.code;
      const amount = this.power.service.min
      if ( this.power.amount < amount ) {
        toaster.error("please specify an amount greater than " + amount );
      }
      delete this.power.confirm
      const data = {
        ...this.power,
        service
      }, self = this;
      this.vendPower(data).then( res => {
        self.powerData = res;
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    changeData(e) {
      this.fetchData(e.target.value)
    },
    changeCable(e) {
      this.fetchCable(e.target.value)
    },
    authorizeFor(method) {
      this.payMethod = method
    },
    authorizationCallback(verified) {
      if (!verified) {
        return;
      }
      const authPaymentModal = document.getElementById('auth-payment-modal');
      const _authPaymentModal = bootstrapBundleMin.Modal.getInstance(authPaymentModal)
      _authPaymentModal.hide();
      if (this.is('restricted')) {
        toaster.info("Cannot perform this transaction!");
        return;
      }
      this[this.payMethod]();
    },
    getDataOptions(data) {
      return data.map(d => {
        const splited = d.description.split("for")
        const desc = splited[1] ? splited[1].trim() : splited[0].trim()
        return {
          ...d,
          name: `${desc} - ${this.formatter("NGN").format(d.amount)}`
        }
      })
    },
    getCableOptions(data) {
      return data.map(d => {
        const splited = d.description.split("for")
        const desc = splited[1] ? splited[1].trim() : splited[0].trim()
        return {
          ...d,
          name: `${desc} - ${this.formatter("NGN").format(d.amount)}`
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.standard-tab .btn.active {
  background-color: var(--jw-text-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
</style>
