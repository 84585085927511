<template>

    <div class="container mt-2 mb-4">
        <div class="card">
        <div class="card-header">
            <h6 class="py-1 fw-bold"><i class="pi pi-sun"></i> Investments</h6>
        </div>
        <div class="card-body" v-if="userInvestmentPlans() && userInvestmentPlans().length > 0">
            <div class="accordion accordion-flush accordion-style-two" id="accordionStyle2">
            <!-- Single Accordion -->
            <div class="accordion-item" v-for="(plan, i) in userInvestmentPlans()" :key="i">
                <div class="accordion-header" :id="`accordion${i}`">
                    <div class="d-flex justify-between">
                        <h6 
                            data-bs-toggle="collapse" 
                            :data-bs-target="`#accordionStyleCol${i}`" 
                            aria-expanded="false" 
                            :aria-controls="`accordionStyleCol${i}`" 
                            class="collapsed"
                        >
                            <div class="col flex-row">
                                <div>{{ plan.name }} </div>
                                <span :class="`badge ${getStatusColor(plan.subscription.status)} mt-2`" style="font-size:8px">{{ plan.subscription.status }}</span>
                                
                            </div> 
                            <font size="1" color="green" class="text text-xs"> (3%)</font>
                            <p class="text-xs text-muted mt-3 mx-4">
                                <small>
                                    {{ moment(plan.subscription.updated_at || plan.subscription.updated_at).format("MMM DD, YYYY HH:mm a") }}
                                </small>
                            </p>
                        </h6>
                        

                        <h6 
                            data-bs-toggle="collapse" 
                            :data-bs-target="`#accordionStyleCol${i}`" 
                            aria-expanded="false" 
                            :aria-controls="`accordionStyleCol${i}`" 
                            class="collapsed"
                            style="display:block;width:0%;padding:0px;margin-bottom: 0.5rem;margin-left: -20px;"
                        >
                            <i class="bi bi-plus-lg"></i>
                        </h6>

                    </div>
                
                </div>

                <div class="accordion-collapse collapse" :id="`accordionStyleCol${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordionStyle2" style="">
                    <div class="accordion-body">
                        
                        <p class="mb-0">{{ plan.subscription.comment }}</p>
                        <h4 class="badge bg-dark">{{ plan.subscription.currency }} {{ plan.subscription.amount.toFixed(2) }} | {{ plan.subscription.base_currency }} {{( plan.subscription.rate * plan.subscription.amount).toFixed(2) }}</h4>
                        <p>{{ plan.subscription.currency }} rate: {{ plan.subscription.rate }} {{ plan.subscription.base_currency }}</p>
                        <p>We have invested {{ plan.subscription.currency }} {{ plan.subscription.amount.toFixed(2) }} of your funds</p>
                        <p>Investment list: </p>
                        <p class="mb-0" v-if="plan.subscription.investments">
                        
                            <table class="table mb-0 table-hover" >
                                <thead>
                                    <th>
                                        Investment
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Due Date
                                    </th>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(investment, j) in plan.subscription.investments" 
                                        :key="j" 
                                        :class="`tb-bg-${investment.status}`" 
                                        :title="investment.status" 
                                        @mouseover="activeInvestment=j" 
                                        @mouseout="activeInvestment=null"
                                    >
                                        <td>
                                            <div class="text-xs">
                                                <!-- {{ formatCurrency({amount: investment.amount, currncy: plan.subscription.currency}) }} -->
                                                {{ plan.subscription.currency }} {{ Math.round(investment.amount * 10)/10 }} 
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-xs">
                                                {{ investment.status }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div class="text-xs" v-if="activeSave==j && ['PENDING', 'DUE'].includes(investment.status)" >
                                                <button class="btn btn-sm btn-success" :disabled="moment(investment.due_at).isAfter(new Date)" @click="makeClaim(plan.subscription)">claim!</button>
                                            </div>
                                            <div v-else>
                                                {{ moment(investment.due_at).format("DD MMM HH:mm a") }}
                                            </div>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </p>
                        <br/>
                        <div class="mt-1 text-end">
                            <button 
                            :id="`terminate-button-${plan.subscription.id}`"
                            class="btn btn-warning btn-sm btn-creative" 
                            data-bs-toggle="modal" 
                            data-bs-target="#terminate" 
                            @click="investmentPlan = plan" 
                            v-if="plan.subscription.status ==='ACTIVE' || plan.subscription.status ==='INITIATED'" 
                            :disabled="plan.subscription.status!=='ACTIVE' && plan.subscription.status !=='INITIATED'">terminate</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="card-body" v-else>
            <div class="text-center">
                <i class="bi bi-clipboard-x"></i>
                Investment is yet to start <br/>
                <small>
                  To start <router-link to="/plans" style="text-decoration: none; color: var(--jw-text-success)"> click here. </router-link>
                </small>
                <img src="../../../assets/images/common/empty.png" />
            </div>
        </div>
        </div>
        <modal title="Terminate Plan" id="terminate">
            <div v-if="Object.values(investmentPlan).length > 0">
                <h6>
                You are about to terminate your investment plan "<b>{{investmentPlan.subscription.name}}</b>"
                </h6>
                <i class="bi bi-clock"></i> Due date: <b>{{ moment(investmentPlan.subscription.created_at).add(parseInt(investmentPlan.subscription.duration), 'M').format("DD MMM HH:mm a") }}</b>
                <div v-if="investmentPlan.is_flexible || moment(investmentPlan.subscription.created_at).add(parseInt(investmentPlan.subscription.duration), 'M').isBefore(new Date)">
                    <i class="bi bi-cash-coin"></i> Termination Fee: 0
                </div>
                <div v-else>
                    <i class="bi bi-cash-coin"></i> Termination before the due date will cost you <b>{{ investmentPlan.opt_out_penalty }}%</b> of your saved amount.
                    <span></span>
                </div>
                <div class="text-end mt-2">
                    <app-button @click="stopSavings()" :classes="'btn-sm btn-danger'" :title="'terminate'" :loading="loaders().abortPlan" :disabled="loaders().abortPlan || terminated" ></app-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapStatusBgColor } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AppButton from '@/components/common/form/AppButton.vue';
import Modal from '@/components/common/modal/Modal.vue'


export default {
  name: 'PlanList',
  props: ['title', 'id'],
  components: {
    AppButton,
    Modal
  },
  data() {
    return {
        terminated: false,
        investmentPlan: {},
        updatePriceInterval: null,
        priceList: {},
        defaultCoinLogo: require("../../../assets/images/common/1027.png"),
        activeInvestment: null
    }
  },
  mounted() {
    const self = this;
    if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    }
  },
  methods: {
    ...mapActions([
        "myAccount",
        "abortPlan"
    ]),
    ...mapGetters([
      "userInvestmentPlans", 
      "account",
      "loaders"
    ]),
    getStatusColor(status) {
      return mapStatusBgColor(status)
    },
    stopSavings() {
      if ( !confirm("Are you sure you want to stop this savings plan?")) {
        return 
      }
      // console.log(this.investmentPlan)
      const id = this.investmentPlan.subscription.id;
      const self = this;
      this.abortPlan(id).catch( (res) => {
        self.terminated = true;
      })
      const elem = document.getElementById("terminate-button-" + id);
      elem.disabled = true;
    },
    makeClaim(sub) {
        console.log("notify redge admin");
    },
    setDefault (img) {
        console.log(img)
    }
  },
  created() {
    this.moment = moment;
  },
  unmounted() {
    if (this.updatePriceInterval) {
      clearInterval(this.updatePriceInterval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tb-bg-ACTIVE {
    background-color:rgba(34, 174, 229, 0.061);
}
/* .tb-bg-DECLINED {
    background-color:rgba(229, 34, 44, 0.061);
} */
.tb-bg-FAILED {
    background-color:rgba(229, 34, 44, 0.061);
}
.tb-bg-COMPLETED {
    background-color:rgba(34, 229, 96, 0.061);
}
.tb-bg-INITIATED {
    background-color:rgba(34, 73, 229, 0.061);
}
.card-header h6 {
  color: var(--jw-text-success) !important;
}

[data-theme=dark] body .card-header h6 {
    color: #ffffff !important;
}

[data-theme=dark] body .card-header {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
