<template>
  <page-template>
    <div class="page-content-wrapper py-0">

      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Withdraw Summary.</h3>
            <!-- <router-link class="btn btn-warning" to="/plans">Start Plan</router-link> -->
          </div>
        </div>
      </div>
      

      <div class="notification-area mt-4">
        
        <div class="container">

          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="form-group mb-0">
                <input class="form-control form-control-clicked" id="elementsSearchInput" type="text" onkeyup="elementsSearch()" placeholder="Search...">
              </div>
            </div>
          </div>

          <!--  -->




          <div class="card">
            <div class="card-body">
              <p>
                Transfer history
              </p>
              <div class="standard-tab">
                <ul class="nav rounded-lg mb-2 p-2 shadow-sm" id="affanTabs1" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="btn active" id="bootstrap-tab" data-bs-toggle="tab" data-bs-target="#bootstrap" type="button" role="tab" aria-controls="bootstrap" aria-selected="false">
                      Bank
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="btn" id="pwa-tab" data-bs-toggle="tab" data-bs-target="#pwa" type="button" role="tab" aria-controls="pwa" aria-selected="false">
                      Wallet
                    </button>
                  </li>
                  <!-- <li class="nav-item" role="presentation">
                    <button class="btn" id="dark-tab" data-bs-toggle="tab" data-bs-target="#dark" type="button" role="tab" aria-controls="dark" aria-selected="true">Sent</button>
                  </li> -->
                </ul>
                <div class="tab-content rounded-lg p-3 shadow-sm" id="affanTabs1Content">
                  <div class="tab-pane fade active show" id="bootstrap" role="tabpanel" aria-labelledby="bootstrap-tab">
                    <h6>Withdraws</h6>
                    <div class="accordion accordion-flush accordion-style-one" v-if="withdraws() && withdraws().data.length > 0" id="accordion1">
                      <!-- Single Accordion -->
                      <div class="accordion-item" v-for="(withdraw, i) in withdraws().data" :key="i">
                        <div class="accordion-header" :id="`accordion${i}`">
                          <h6 data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                            <div class="flex justify-between">
                              <small class="mx-1">{{ moment(withdraw.created_at).format("Do MMM YY") }}</small><br/>
                              <b> {{ withdraw.currency }} {{ withdraw.amount.toFixed(2) }}</b>
                            </div>
                            <div class="d-flex">
                              <small :class="`m-1 ${ ( withdraw.status === 'SUCCESSFUL') ? 'text-success' : 'text-secondary'}`" style="cursor: pointer" >{{ withdraw.status }}</small>
                              <i class="bi bi-chevron-down"></i>
                            </div>
                          </h6>
                        </div>
                        <div class="accordion-collapse collapse" :id="`accordionStyle${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordion1" style="">
                          <div class="accordion-body">
                            <p class="mb-0">
                              
                              <table class="table mb-0 table-hover">
                                <tbody>
                                  <tr>
                                    <td>amount</td>
                                    <td>{{ withdraw.currency }} {{ withdraw.amount.toFixed(2) }}</td>
                                  </tr>
                                  <tr>
                                    <td>reference</td>
                                    <td>{{ withdraw.reference }}</td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>{{ moment(withdraw.created_at).format("YYYY-MM-DD") }}</td>
                                  </tr>
                                  <tr>
                                    <td>comment</td>
                                    <td>{{ withdraw.comment }}</td>
                                  </tr>
                                  <!-- <tr v-for="(msg, j) in JSON.parse(trx.meta)" :key="j">
                                    <td>Charge</td>
                                    <td>{{ msg }}</td>
                                  </tr> -->
                                </tbody>
                              </table>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="mb-0" v-else>
                       <p class="text-center">Empty list</p>
                       <div>
                          <router-link class="btn btn-warning" to="/withdraw">Withdraw Fund</router-link>
                        </div>
                     </div>
                  </div>

                  <div class="tab-pane fade" id="pwa" role="tabpanel" aria-labelledby="pwa-tab">
                    <h6>Transfers</h6>
                    <div class="accordion accordion-flush accordion-style-one" v-if="transactions() && transactions().data.length > 0" id="accordion2">
                      <!-- Single Accordion -->
                      <div class="accordion-item" v-for="(trn, i) in transactions().data" :key="i">
                        <div class="accordion-header" :id="`accordion${i}`">
                          <h6 data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                            <div class="flex justify-between">
                              <small class="mx-1">{{ moment(trn.created_at).format("Do MMM YY") }}</small><br/>
                              <b> {{ trn.currency }} {{ trn.gross_amount.toFixed(2) }}</b>
                            </div>
                            <div class="d-flex">
                              <small :class="`m-1 ${ ( trn.status === 'SUCCESSFUL') ? 'text-success' : 'text-secondary'}`" style="cursor: pointer" >{{ trn.status }}</small>
                              <i class="bi bi-chevron-down"></i>
                            </div>
                          </h6>
                        </div>
                        <div class="accordion-collapse collapse" :id="`accordionStyle${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordion2" style="">
                          <div class="accordion-body">
                            <p class="mb-0">
                              
                              <table class="table mb-0 table-hover">
                                <tbody>
                                  <tr>
                                    <td>amount</td>
                                    <td>{{ trn.currency }} {{ trn.gross_amount.toFixed(2) }}</td>
                                  </tr>
                                  <tr>
                                    <td>reference</td>
                                    <td>{{ trn.reference }}</td>
                                  </tr>
                                  <tr>
                                    <td>Charge</td>
                                    <td>{{ trn.currency }} {{ trn.charge }}</td>
                                  </tr>
                                  <tr>
                                    <td>comment</td>
                                    <td>{{ trn.description }}</td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>{{ moment(trn.created_at).format("YYYY-MM-DD") }}</td>
                                  </tr>
                                  <!-- <tr v-for="(msg, j) in JSON.parse(trx.meta)" :key="j">
                                    <td>Charge</td>
                                    <td>{{ msg }}</td>
                                  </tr> -->
                                </tbody>
                              </table>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-0" v-else>
                      <p class="text-center">Empty list</p>
                      <div>
                        <router-link class="btn btn-warning" to="/wallet">Sell Coin</router-link>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="tab-pane fade" id="pwa" role="tabpanel" aria-labelledby="pwa-tab">
                    <h6>Sell Order</h6>
                    <div class="accordion accordion-flush accordion-style-one" v-if="sells() && sells().data.length > 0" id="accordion3">
                      <div class="accordion-item" v-for="(sell, i) in sells().data" :key="i">
                        <div class="accordion-header" :id="`accordion${i}`">
                          <h6 data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                            <div class="flex justify-between">
                              <span :class="`m-1 badge ${ ( sell.status === 'SUCCESSFUL') ? 'bg-success' : 'bg-secondary'}`" style="cursor: pointer" >{{ sell.status }}</span>
                              <span> {{ sell.currency }} {{ sell.amount }}</span>
                            </div>
                            <i class="bi bi-chevron-down"></i>
                          </h6>
                        </div>
                        <div class="accordion-collapse collapse" :id="`accordionStyle${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordion3" style="">
                          <div class="accordion-body">
                            <p class="mb-0">
                              
                              <table class="table mb-0 table-hover">
                                <tbody>
                                  <tr>
                                    <td>amount</td>
                                    <td>{{ sell.currency }} {{ sell.amount }}</td>
                                  </tr>
                                  <tr>
                                    <td>reference</td>
                                    <td>{{ sell.reference }}</td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>{{ sell.created_at }}</td>
                                  </tr>
                                  <tr>
                                    <td>comment</td>
                                    <td>{{ sell.comment }}</td>
                                  </tr>
                                    <tr v-for="(msg, j) in JSON.parse(trx.meta)" :key="j">
                                    <td>Charge</td>
                                    <td>{{ msg }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-0" v-else>
                      <p class="text-center">Empty list</p>
                      <div>
                        <router-link class="btn btn-warning" to="/wallet">Sell Coin</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="dark" role="tabpanel" aria-labelledby="dark-tab">
                    <h6>Send Order</h6>
                    <div class="accordion accordion-flush accordion-style-one" v-if="sends() && sends().data.length > 0" id="accordion4">
                      <div class="accordion-item" v-for="(send, i) in sends().data" :key="i">
                        <div class="accordion-header" :id="`accordion${i}`">
                          <h6 data-bs-toggle="collapse" :data-bs-target="`#accordionStyle${i}`" aria-expanded="false" :aria-controls="`accordionStyle${i}`" class="collapsed">
                            <div class="flex justify-between">
                              <span :class="`m-1 badge ${ ( send.status === 'SUCCESSFUL') ? 'bg-success' : 'bg-secondary'}`" style="cursor: pointer" >{{ send.status }}</span>
                              <span> {{ send.currency }} {{ send.amount }}</span>
                            </div>
                            <i class="bi bi-chevron-down"></i>
                          </h6>
                        </div>
                        <div class="accordion-collapse collapse" :id="`accordionStyle${i}`" :aria-labelledby="`accordion${i}`" data-bs-parent="#accordion4" style="">
                          <div class="accordion-body">
                            <p class="mb-0">
                              
                              <table class="table mb-0 table-hover">
                                <tbody>
                                  <tr>
                                    <td>amount</td>
                                    <td>{{ send.currency }} {{ send.amount }}</td>
                                  </tr>
                                  <tr>
                                    <td>reference</td>
                                    <td>{{ send.reference }}</td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>{{ send.created_at }}</td>
                                  </tr>
                                  <tr>
                                    <td>comment</td>
                                    <td>{{ send.comment }}</td>
                                  </tr>
                                   <tr v-for="(msg, j) in JSON.parse(trx.meta)" :key="j">
                                    <td>Charge</td>
                                    <td>{{ msg }}</td>
                                  </tr> 
                                </tbody>
                              </table>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-0" v-else>
                      <p class="text-center">Empty list</p>
                      <div>
                        <router-link class="btn btn-warning" to="/wallet">Send Coin</router-link>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>

  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from "moment";

export default {
  name: 'Widthdraw',
  components: {
    PageTemplate,
    Modal
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      banner: banner || require("@/assets/images/common/22.jpeg"),
    }
  },
  mounted() {
    this.fetchWithdraw();
    // this.fetchSell();
    // this.fetchSend();
    this.getTransactions("?category=wallet-transfer")
  },
  created() {
    this.moment = moment
  },
  methods: {
    ...mapActions([
      "fetchWithdraw",
      // "fetchSell",
      // "fetchSend",
      "getTransactions"
    ]),
    ...mapGetters([
      "loaders",
      "withdraws",
      "transactions",
      // "sends",
      // "sells",
      "appConfig"
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .standard-tab .btn.active {
      background-color: var(--jw-text-success);
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
  }
</style>
