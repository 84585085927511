<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay rounded-0" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-0 py-5">
            <h3 class="mb-3 text-white">Referral Details.</h3>

            <div class="btn-group" role="group" aria-label="Basic outlined example">
              <button class="btn btn-outline-warning" style="border-right: 1px" @click="load24hrsTransactions()">last 24hr</button>
              <button class="btn btn-outline-warning" @click="load1WeekTransactions()">1 week</button>
              <button class="btn btn-outline-warning" @click="load30DaysTransactions()">30 days</button>
            </div>

          </div>
        </div>
      </div>

      <div class="notification-area mt-4">
        
        <div class="container">

          <div class="standard-tab">
            <ul class="nav rounded-lg mb-2 p-2 shadow-sm" id="affanTabs1" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="btn active" id="referrer-details" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="false">
                  Details
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="btn" id="earnings-tab" data-bs-toggle="tab" data-bs-target="#earnings" type="button" role="tab" aria-controls="earnings" aria-selected="false">
                  Earnings
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="btn" id="referree-tab" data-bs-toggle="tab" data-bs-target="#referree" type="button" role="tab" aria-controls="referree" aria-selected="false">
                  Referred
                </button>
              </li>
            </ul>
            <div class="tab-content rounded-lg p-3 shadow-sm" id="affanTabs1Content">
              <div class="tab-pane fade active show" id="details" role="tabpanel" aria-labelledby="referrer-details">
                <div class="card-header"><h6>Details</h6></div>
                <div class="accordion accordion-flush accordion-style-one" id="accordion1">
                  Tell a friend about Redge and we pay you N200 when your friend makes a transaction with us.
                  <hr/>
                  <div class="text-xs" @click="clickToCopy(`${settings().referrer_code || ''}`)">Code: <span class="m-1 badge rounded-pill bg-light text-muted">{{ settings().referrer_code || "nil" }}</span> <i class="bi bi-files text-xs"></i></div>
                  <div class="text-xs" @click="clickToCopy(`https://app.redge.ng?referrer=${settings().referrer_code || ''}`)">Link: <span class="m-1 badge rounded-pill bg-light text-muted">https://app.redge.ng?referrer={{ settings().referrer_code || '' }}</span> <i class="bi bi-files text-xs"></i></div>
                  <div class="text-xs">Points: {{ auth().meta ? auth().meta.points : 0 }} </div>
                  <hr/>
                  <h6>Share on socials</h6>
                  <ul>
                    <li><i class="bi bi-whatsapp text-xs"></i> Whatsapp: 
                      <a class="text-grey text-decoration-none" :href="`whatsapp://send?text=${getReferrerCopy()}`" data-action="share/whatsapp/share">Group</a> | 
                      <a class="text-grey text-decoration-none" :href="`whatsapp://send?text=${getReferrerCopy()}`" data-action="share/whatsapp/share">Status</a> | 
                      <a class="text-grey text-decoration-none" :href="`whatsapp://send?text=${getReferrerCopy()}`" data-action="share/whatsapp/share">Friend</a></li>
                    <li><i class="bi bi-facebook text-xs"></i> Facebook: 
                      <a class="text-grey text-decoration-none" title="share redge" :href="`http://www.facebook.com/sharer.php?u=http://www.redge.ng&t=${getReferrerCopy()}`" target="_blank">Post</a>
                    </li>
                    <li><i class="bi bi-instagram text-xs"></i> Instagram: <a class="text-grey text-decoration-none" href="#" @click="shareImageAsset">Post</a></li>
                    <li>
                      <i class="bi bi-twitter text-xs"></i> Twitter: 
                        <a class="text-grey text-decoration-none twitter-share-button"
                        :href="`https://twitter.com/intent/tweet?text=${getReferrerCopy()}`" hashtags="redge,savings,loan,investment,nigeria">Tweet</a>
                    </li>
                    <li><i class="bi bi-linkedin text-xs"></i> LinkedIn: 
                      <a class="text-grey text-decoration-none" :href="`https://linkedin.com/shareArticle?url=https://www.redge.ng&title=${getReferrerCopy()}`" target="_blank">Share</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">

                  <!-- <div class="card mb-4">
                    <div class="card-body p-3">
                      <div class="form-group mb-0">
                        <input class="form-control form-control-clicked" id="elementsSearchInput" type="text" :onkeyup="searchTransactions" placeholder="Search...">
                      </div>
                    </div>
                  </div> -->

                  <transaction-list :transactions="transactionList" topic="Referral Earnings"></transaction-list>
              
                  <div class="d-flex justify-content-center">
                    <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === 1" @click="this.loadMoretransaction(`${{...this.query, page:transactions().current_page-1}}`)">
                      Prev
                    </button>
                    <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === transactions().last_page" @click="this.loadMoretransaction(`${{...this.query, page:transactions().current_page+1}}`)">
                      Next
                    </button>
                  </div>
                  
              </div>
              <div class="tab-pane fade" id="referree" role="tabpanel" aria-labelledby="referree-details">
                <div class="card-header"><h6>Referred</h6></div>
                <div class="accordion accordion-flush accordion-style-one" id="accordion1">
                  <span>pending...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { clickToCopy } from '@/utils'

export default {
  name: 'Transaction',
  components: {
    PageTemplate,
    TransactionList
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      query: {},
      banner: banner || require("@/assets/images/common/22.jpeg"),
      transactionList: [],
      timer: null,
    }
  },
  mounted() {
    this.initQuery()
    this.getTransactions(this.query).then(() => {
      this.filterTransaction();
    });
    if (Object.values(this.settings()).length === 0) {
      this.getSettings()
    }
  },
  created() {
    this.clickToCopy = clickToCopy
  },
  methods: {
    ...mapActions([
      "getTransactions",
      "getSettings"
    ]),
    ...mapGetters([
      "transactions",
      "activeAccount",
      "appConfig",
      "settings",
      "auth"
    ]),
    initQuery() {
      let category = "referral"
      this.query = {
        ...this.query,
        category
      }
    },
    loadMoretransaction(query) {
      const self = this;
      this.getTransactions(query).then(() => {
        self.filterTransaction();
      })
    },
    load24hrsTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load1WeekTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load30DaysTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'M').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    searchTransactions(e) {
      const value = e.target.value;
      this.filterTransaction(value)
    },
    filterTransaction(search = null) {
      if (!this.transactions().data || this.transactions().data.length === 0) {
        this.transactionList = [];
        return;
      }

      const transactions = this.transactions().data;

      if (search === null) {
        this.transactionList = transactions;
        return;
      }

      this.transactionList = transactions.filter( transaction => (
        transaction.category.includes(search) ||
        transaction.description.includes(search) ||
        `${transaction.net_amount}`.includes(search) ||
        `${transaction.gross_amount}`.includes(search) ||
        transaction.reference.includes(search) ||
        transaction.status.includes(search) ||
        transaction.type.includes(search) 
      ));

      this.query = {
        search
      }
      if ( this.timer !== null ) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.loadMoretransaction(this.query)
      }, 2000)
      

    },
    getReferrerCopy() {
      return `Join us on Redge and build wealth. https://app.redge.ng?referrer=${this.settings().referrer_code}`;
    },
    async shareImageAsset() {
      const title = "loan.6e5bb646.jpeg";
      const titles = title.split(".")

      const response = await fetch(`https://partner.redge.ng/img/${title}`);
      const blob = await response.blob();
      const filesArray = [
        new File([blob], `${title}`, {
          type: `image/${titles[titles.length - 1]}`,
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        title: `${title}`,
        files: filesArray,
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
</style>
