export const strings = {
    methods: {
      clickToCopy(text) {
        navigator.clipboard.writeText(text).then(function() {
          toaster.success('Copied to clipboard!');
        }, function(err) {
          toaster.show('Could not copy text: ', err);
        });
      },
    }
}