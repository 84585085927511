<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      <div class="container">
        <!-- User Information-->
        <div class="card user-info-card mb-3">
          <div class="card-body align-items-center">
            <div class="settings-option">
              <router-link to="/profile">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ">Update profile</p>
                  <i class="bi bi-person-check text-xs"></i>
                </div>
              </router-link>
            </div>
            <hr/>
            <div class="settings-option">
              <router-link to="/verify">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ">Verification</p>
                  <i class="bi bi-patch-check-fill text-xs"></i>
                </div>
              </router-link>
            </div>
            <hr/>
            <div class="settings-option">
              <router-link to="/bank-account">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ">Bank Accounts</p>
                  <i class="bi bi-bank text-xs"></i>
                </div>
              </router-link>
            </div>
            <hr/>
            <div class="settings-option">
              <router-link to="/questions">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ">Insight</p>
                  <i class="bi bi-lightning-charge text-xs"></i>
                </div>
              </router-link>
            </div>
            
            <!-- <hr/>
            <div class="">
              <router-link to="/security-questions">
                <div class="">
                  <p class="mb-1 ">Security Questions</p>
                </div>
              </router-link>
            </div> -->
            <hr/>
             <div class="settings-option">
              <router-link to="/withdraw">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ">Withdraw</p>
                  <ul class="icon-in icon-group-transfers">
                    <li class="path1"></li>
                    <li class="path2"></li>
                    <li class="path3"></li>
                    <li class="path4"></li>
                    <li class="path5"></li>
                    <li class="path6"></li>
                    <li class="path7"></li>
                    <li class="path8"></li>
                    <li class="path9"></li>
                    <li class="path10"></li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="card user-data-card mb-3">
          <div class="card-body">
            <form action="#">
              <!-- <div class="form-group mb-3">
                <label class="form-label" for="Account Name">Account Name</label>
                <input class="form-control" id="AccountName" v-model="settings.account_name" type="text" placeholder="Account Name">
              </div> -->

              <h6>
                <i class="bi bi-bag text-xs"></i>
                Transaction
              </h6>
              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="autoTrade" type="checkbox" v-model="v$.options.auto_trade.$model" :checked="options.auto_trade">
                <label class="form-check-label" for="autoTrade">
                  <i class="bi bi-badge-tm text-xs"></i>
                  Auto Trade
                </label>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="autoSave" type="checkbox" v-model="v$.options.auto_save.$model" :checked="options.auto_save">
                <label class="form-check-label" for="autoSave">
                  <i class="bi bi-wallet text-xs"></i>
                  Auto Save
                </label>
              </div>

              <hr/>
              <h6><i class="bi bi-info-circle text-xs"></i> Subscriptions and Notification</h6>
              <div class="mb-4">
                <!-- <small :class="`${( auth().meta && moment(auth().meta.sub).isBefore(new Date)) ? 'text-danger' :'text-success'}`">{{ auth().meta ? ( moment(auth().meta.sub).isBefore(new Date) ? 'expired on: ' : 'plan active till: ') + moment(auth().meta.sub).format("ddd MMM DD, YYYY [at] HH:mm a") : '' }}</small><br/> -->
                <small :class="`${!has('data.vend.create') ? 'text-danger' :'text-success'}`">
                  {{ 
                    has('data.vend.create') ? 
                    'You are an active data reseller on redge' : 
                    'Earn more by selling SME data at a cheap price to your customers' 
                  }}
                </small>
                <br/>
                <button v-if="!has('data.vend.create')" class="btn btn-warning btn-sm mt-2" data-bs-toggle="modal" data-bs-target="#subscribe" type="button">
                  become a data vendor
                </button>
                <router-link v-else class="stretched-link" :to="{ path: 'services', query: { type: 'data' }}">
                  sell now
                </router-link>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="receiveSms" type="checkbox" v-model="v$.options.enable_sms.$model" :checked="options.enable_sms">
                <label class="form-check-label" for="receiveSms">
                  <i class="bi bi-chat text-xs"></i>
                  Receive Sms
                </label>
              </div>

              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="receiveEmail" type="checkbox" v-model="v$.options.enable_email.$model" :checked="options.enable_email">
                <label class="form-check-label" for="receiveEmail">
                  <i class="bi bi-envelope text-xs"></i>
                  Recieve Email
                </label>
              </div>

              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="receiveTelegram" type="checkbox" v-model="v$.options.enable_telegram.$model" :checked="options.enable_telegram">
                <label class="form-check-label" for="receiveTelegram">
                  <i class="bi bi-telegram text-xs"></i>
                  Recieve Telegram
                </label>
              </div>

              <div class="form-check form-switch">
                <input class="form-check-input form-check-success" @change="updateSettings(options)" id="receiveWhatApp" type="checkbox" v-model="v$.options.enable_whatsapp.$model" :checked="options.enable_whatsapp">
                <label class="form-check-label" for="receiveWhatApp">
                  <i class="bi bi-whatsapp text-xs"></i>
                  Recieve WhatsApp
                </label>
              </div>


              <!-- <button class="btn btn-success w-100 mt-2" type="submit">Update Now</button> -->
            </form>
            <hr/>

            <h6>Referrer</h6>
            <div class="text-xs" @click="clickToCopy(`${options.referrer_code || ''}`)">
              Code<span class="m-1 badge rounded-pill bg-dark text-light">
              {{ options.referrer_code || "nil" }}</span> 
              <i class="bi bi-files text-xs"></i>
            </div>
            <div class="text-xs" @click="clickToCopy(`https://app.redge.ng?referrer=${options.referrer_code || ''}`)">
              <i class="bi bi-link-45deg text-xs"></i>
              <span class="m-1 badge rounded-pill bg-dark text-light">https://app.redge.ng?referrer={{ options.referrer_code || '' }}</span> 
              <i class="bi bi-files text-xs"></i>
            </div>
            <div class="text-xs">
              <ul class="icon-in icon-gift-box">
                <li class="path1"></li>
                <li class="path2"></li>
                <li class="path3"></li>
                <li class="path4"></li>
                <li class="path5"></li>
                <li class="path6"></li>
                <li class="path7"></li>
                <li class="path8"></li>
                <li class="path9"></li>
                <li class="path10"></li>
              </ul>
              {{ auth().meta ? auth().meta.points : 0 }} 
              Points
            </div>
            <router-link to="/referral" class="btn btn-warning btn-sm mt-2">
              details
            </router-link>
          </div>
        </div>

        <div class="card user-data-card mb-3">
          <div class="card-body">
            <h6>
              <i class="bi bi-gear-fill text-xs"></i>
              Security
            </h6>
            <hr/>
            <div class="mt-2" v-if="!userProfile().phone">
              <label class="form-check-label" data-bs-toggle="modal" data-bs-target="#add-phone">
                <i class="bi bi-phone text-xs"></i>&nbsp;
                Add Phone Number
              </label>
            </div>
            <div class="mt-2">
              <label class="form-check-label" data-bs-toggle="modal" data-bs-target="#change-password">
                <i class="bi bi-key-fill text-xs"></i>&nbsp;
                Change Password
              </label>
            </div>
            <div class="mt-2">
              <label class="form-check-label" @click="$refs.transactionPin.handleResetPin()">
                <i class="bi bi-shield-lock-fill text-xs"></i>&nbsp;
                Change Transaction Pin
              </label>
            </div>
            <div class="mt-2">
              <label class="form-check-label" data-bs-toggle="modal" data-bs-target="#change-email">
                <i class="bi bi-envelope text-xs"></i>&nbsp;
                Change Email
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal title="Change E-mail" id="change-email">
      <form action="#">
        <div class="form-group mb-3">
          <label class="form-label" for="addEmailInput">Change Email</label>
          <input class="form-control" id="addEmailInput" v-model="settings.account_name" type="text" placeholder="New Email">
        </div>
        <button class="btn btn-success w-100" type="submit">Update</button>
      </form>
    </modal>

    <modal-change-phone id="add-phone"></modal-change-phone>

    <modal title="Connect Binance" id="connect-binance">
      <form>
        <div class="form-group mb-3">
          <label class="form-label" for="apiKey">Api Key</label>
          <input class="form-control" id="apiKey" v-model="v$.options.binance_api.$model" type="text" placeholder="Api Key">
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.options.binance_api.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="form-label" for="secretKey">Secret Key</label>
          <input class="form-control" id="secretKey" v-model="v$.options.binance_secret.$model" type="text" placeholder="Secret Key">
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.options.binance_secret.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <button class="btn btn-success w-100" type="button" @click="updateSettings(options)" :disabled="loaders().updateSettings || v$.options.$invalid" >Connect</button>
      </form>
    </modal>

    <modal title="Change Password" id="change-password">
      <form>
        <div class="form-group mb-3">
          <label class="form-label" for="OldPassword">Old Password</label>
          <input class="form-control" id="OldPassword" v-model="v$.password.old_password.$model" :type="passwordType" placeholder="New Password">
          <div class="position-absolute" @click="changePasswordType" id="password-visibility"><i class="bi bi-eye" v-if="passwordType === 'password'"></i><i class="bi bi-eye-slash" v-else></i></div>

          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.password.old_password.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>


        <div class="form-group mb-3">
          <label class="form-label" for="NewPassword">New Password</label>
          <input class="form-control" id="NewPassword" v-model="v$.password.new_password.$model" :type="passwordType" placeholder="New Password">

          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.password.new_password.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label" for="ConfirmPassword">Confirm Password</label>
          <input class="form-control" id="ConfirmPassword" v-model="v$.password.confirm_password.$model" :type="passwordType" placeholder="Confirm Password">

          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.password.confirm_password.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <button class="btn btn-success w-100" @click="changePass" :disabled="loaders().resetPassword || v$.password.$invalid" type="button">Update</button>
      </form>
    </modal>

    <modal-transaction-pin id="transaction-pin" ref="transactionPin"></modal-transaction-pin>

    <modal title="Subscribe" id="subscribe">
      <form v-if="!confirmReciept">
        <p>This service cost a one time subscription fee of <b>{{ formatter("NGN").format(500) }}</b>.</p> 
        <p>Click on the proceed button below to continue</p>
        <app-button @click="confirmReciept = true" :title="'proceed'" :loading="loaders().subscribeForData" :disabled="loaders().subscribeForData" ></app-button>
      </form>

      <div class="card invoice-card shadow" v-else>
        <div class="card-body">
          <!-- Download Invoice -->
          <div class="download-invoice text-end mb-3">
            <a class="btn btn-sm btn-primary me-2" href="#" @click="printDiv('invoice-div')">
              <i class="bi bi-file-earmark-pdf"></i> PDF
            </a>
            <a class="btn btn-sm btn-light" href="#" @click="printDiv('invoice-div')">
              <i class="bi bi-printer"></i> Print
            </a>
          </div>

          <div id="invoice-div">
            <!-- Invoice Info -->
            <div class="invoice-info text-end mb-4">
              <h5 class="mb-1 fz-14"> Data vendor subscription</h5>
              <!-- <h6 class="fz-12">valid till: {{ auth().meta ? moment(auth().meta.sub).add(1, 'M').format("ddd MMM DD, YYYY [at] HH:mm a") : '--:--' }}</h6> -->
              <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
            </div>

            <!-- Invoice Table -->
            <div class="invoice-table">
              <div class="table-responsive">
                <table class="table table-bordered caption-top">
                  <caption>Payment</caption>
                  <thead class="table-light">
                    <tr>
                      <th>Sl.</th>
                      <th>Description</th>
                      <th>Unit</th>
                      <th>Q.</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>1</td>
                      <!-- <td>Signal alert extention till {{ auth().meta ? moment(auth().meta.sub).add(1, 'M').format("ddd MMM DD, YYYY [at] HH:mm a") : '--:--' }}</td> -->
                      <td>Data vendor subscription fee</td>
                      <td>1</td>
                      <td>1</td>
                      <!-- <td>{{ auth() ? auth().settings.base_currency : "NGN"}} {{ 10 * auth().meta.buy_rate }}</td> -->
                       <td>{{ formatter(auth() ? auth().settings.base_currency : "NGN").format(500) }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="table-light">
                    <tr>
                      <td class="text-end" colspan="4">Total:</td>
                      <!-- <td class="text-end">{{ auth() ? auth().settings.base_currency : "NGN"}} {{ 10 * auth().meta.buy_rate }}</td> -->
                       <td>{{ formatter(auth() ? auth().settings.base_currency : "NGN").format(500) }}</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Fee (0%):</td>
                      <!-- <td class="text-end">{{ auth() ? auth().settings.base_currency : "NGN"}} 0</td> -->
                       <td>{{ formatter(auth() ? auth().settings.base_currency : "NGN").format(0) }}</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Grand Total:</td>
                      <!-- <td class="text-end">{{ auth() ? auth().settings.base_currency : "NGN"}} {{ 10 * auth().meta.buy_rate }}</td> -->
                       <td>{{ formatter(auth() ? auth().settings.base_currency : "NGN").format(500) }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="text-center my-1">
            <app-button @click="subscribe" :disabled="loaders().subscribeForData" :title="'Confirm'" :loading="loaders().subscribeForData" ></app-button>
          </div>

          <p class="mb-0">Notice: This is auto generated invoice.</p>
        </div>
      </div>
    </modal>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
// import { countries } from '@/assets/js/form-autocomplete';
import useVuelidate from '@vuelidate/core';
import Modal from '@/components/common/modal/Modal.vue'
import { required, minLength, maxLength, numeric, sameAs } from '@vuelidate/validators';
import moment from 'moment';
import { printDiv, formatter } from '@/utils';
import AppButton from '@/components/common/form/AppButton.vue';
import ModalChangePhone from '@/components/common/modal/ModalChangePhone.vue'
import ModalTransactionPin from '@/components/common/modal/ModalTransactionPin.vue'
import { clickToCopy } from '@/utils'

export default {
  name: 'SettingsPage',
  components: {
    PageTemplate,
    Modal,
    AppButton,
    ModalChangePhone,
    ModalTransactionPin
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      confirmReciept: false,
      account: {},
      options: {},
      password: {},
      pin: {},
      passwordType: "password",
    }
  },
  validations() {
    return {
      password: {
        old_password: { required, min: minLength(6) },
        new_password: { required, min: minLength(6) },
        confirm_password: { required, min: minLength(6) },
      },
      pin: {
        old_pin: { required, min: minLength(4), max: maxLength(4), numeric },
        pin: { required, min: minLength(4), max: maxLength(4), numeric },
        confirm_pin: { required, min: minLength(4), max: maxLength(4), sameAs: sameAs(this.pin.pin), numeric },
      },
      options: {
        auto_trade: {},
        auto_save: {},
        enable_sms: {},
        enable_email: {},
        enable_telegram: {},
        enable_whatsapp: {},
        // enable_sms: {},
        binance_api: { required, min: minLength(6) },
        binance_secret: { required, min: minLength(6) }
      },
    }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter;
    this.clickToCopy = clickToCopy;
  },
  mounted() {
    const self = this;
    this.getSettings().then( res => {
      self.options = res// self.settings()
    })
    if (Object.keys(this.userProfile()).length === 0) {
      this.getProfile()
    }
  },
  methods: {
    // console.log(this.countries)
    ...mapActions([
      "changePassword",
      "changePin",
      "getSettings",
      "addSettings",
      "updateSettings",
      "subscribeForAlert",
      "subscribeForData",
      "getProfile",
      "updatePhone",
      "phoneCode",
      "transactionPinStatus"
    ]),
    ...mapGetters([
      "loaders",
      "settings",
      "auth",
      "userProfile",
      "walletPinStatus"
    ]),
    changePasswordType() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },
    changePass() {
      this.changePassword({old_password: this.password.old_password, new_password: this.password.new_password})
    },
    subscribe() {
      const userUuid = window.localStorage.getItem("uuid");
      this.subscribeForData(userUuid)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    text-decoration: none;
  }

  .settings-option p, .settings-option i{
    color: var(--jw-text-success);
  }

  [data-theme=dark] body .settings-option p,   [data-theme=dark] body .settings-option i {
    color: #ffffff;
  }

  [data-theme=dark] body .text-success {
    color: #2ecc4a !important
}
</style>
