<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h6><i class="pi pi-receipt"></i> Asset Suggestions</h6>
        <p class="text-sm placeholder-glow">
          we are collecting some nice assets for you 
          <router-link v-if="(auth().meta && moment(auth().meta.sub).isBefore(new Date))" :style="'text-decoration: none'" to="/settings" :class="`${ ! auth().meta ? 'placeholder placeholder-teal-400' : 'text-danger'}`">(expired)</router-link>
        </p>

        <div class="row g-3 asset-slide" >
          <template v-if=" ! auth().meta || (auth().meta && moment(auth().meta.sub).isBefore(new Date))">
            <div class="col-4" v-for="(count, i) in [1, 2, 3]" :key="i">
              <div class="card partner-slide-card border">
                <div class="card-body p-2 placeholder-glow">
                  <span class="placeholder col-3"></span>
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-7"></span>
                </div>
              </div>
            </div>
          </template>
          <div class="col-4" v-for="(prediction, i) in __predictions.first_match" :key="i">
            <!-- Partner Slide Card -->
            <div class="card partner-slide-card border">
              <div class="card-body p-2">
                <small class="text-xs">{{ prediction.symbol.substring(0,3) }}</small>
                <router-link :to="`/trade/${prediction.symbol}`" style="text-decoration:none" :class="`${ prediction.percent_change_24h < 0 ? 'text-danger' : 'text-success'}`">
                  &nbsp;<img class="coin-img" style="width:17px" :src="`${ getImg(prediction) }`" alt="logo"> &nbsp;
                  <div class="text-xs">{{ Math.round( prediction.percent_change_24h * 10 ) / 10 }} %</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="text-end">
          <router-link :class="`${( auth().meta && moment(auth().meta.sub).isBefore(new Date)) ? 'text-danger' :'text-success'}`" :style="'text-decoration: none'" to="/settings">{{ auth().meta ? ( moment(auth().meta.sub).isBefore(new Date) ? 'expired on: ' : 'plan active till: ') + moment(auth().meta.sub).format("MMM DD, YYYY") : '' }}</router-link>
          <!-- <router-link class="btn m-1 btn-light" to="/settings">
            <svg class="bi bi-check2-circle me-2" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
              <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"></path>
            </svg> 
            {{ ( auth().meta && moment(auth().meta.sub).isAfter(new Date)) ? 'top-up': 'renew' }}
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Prediction',
  components: {
  },
  data() {
    return {
      __predictions: {
        first_match: [],
      },
      slider: null
    }
  },
  created() {
    this.moment = moment
  },
  mounted() {

  },
  methods: {
    ...mapActions([
    ]),
    ...mapGetters([
      "loaders",
      "auth"
    ]),
    getImg(prediction) {
      return prediction.id > 8030 ? "https://raw.githubusercontent.com/coinwink/cryptocurrency-logos/master/coins/16x16/1027.png" : prediction.logo;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
