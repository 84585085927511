<template>
  <page-template>
    <div class="page-content-wrapper py-3">
      

      <div class="container mb-4">
        <div class="card direction-rtl">
          <div class="card-body">
            <h6 class="text-center"> 
              <!-- <i class="bi bi-shield-lock-fill" style="font-size:xx-large"></i>
              <br/> -->
              Request a loan and get paid within 24hrs. Choose the most convinient from list of options.
            </h6>
            <div class="d-flex justify-content-center">
              <img src="../../../assets/images/plan/save-up.png" class="img-plan"/>
              <h2 class="text-center text-green-700 bg-gray-700 d-flex align-items-center">
                <!-- USD rate: {{ rates() ? rates()[auth().settings.base_currency].buy_rate : "--" }} -->
                Low interest
              </h2>
            </div>
            
          </div>
        </div>
      </div>


      <div class="container">
        <div class="card">
          <div class="card-body">
            <!-- Price Table One -->
            <div class="price-table-one">
              <ul class="nav nav-tabs border-bottom-0 mb-3 align-items-center justify-content-center" id="priceTab" role="tablist">
                <template v-for="(plan, i) in loanPlans()" :key="i">
                  <li
                    class="nav-item"
                    role="presentation"
                    v-if="plan.is_active"
                  >
                    <a 
                      class="nav-link shadow" 
                      :id="`priceTab${i}`" 
                      data-bs-toggle="tab" 
                      :href="`#priceTab_${i}`" 
                      role="tab" 
                      :aria-controls="`priceTab_${i}`" 
                      aria-selected="false"
                    >
                      <i class="bi bi-flower1"></i>
                    </a>
                  </li>
                </template>
                <!-- <li class="nav-item" role="presentation"><a class="nav-link shadow" id="priceTab0" data-bs-toggle="tab" href="#priceTab_0" role="tab" aria-controls="priceTab_0" aria-selected="false"><i class="bi bi-bullseye"></i></a></li>
                <li class="nav-item" role="presentation"><a class="nav-link shadow" id="priceTab1" data-bs-toggle="tab" href="#priceTab_1" role="tab" aria-controls="priceTab_1" aria-selected="false"><i class="bi bi-hourglass-split"></i></a></li>
                <li class="nav-item" role="presentation"><a class="nav-link shadow active" id="priceTab2" data-bs-toggle="tab" href="#priceTab_2" role="tab" aria-controls="priceTab_2" aria-selected="true"><i class="bi bi-key"></i></a></li> -->
              </ul>
              <div class="tab-content" id="priceTabContent">
                <template v-for="(plan, i) in loanPlans()" :key="i">
                  <div v-if="plan.is_active" :class="`tab-pane fade ${i === /**( loanPlans().length - 1)**/ 0 ? 'active show' :''}`" :id="`priceTab_${i}`" role="tabpanel" aria-labelledby="priceTabOne">
                    <!-- Single Price Table -->
                    <div class="single-price-content shadow-sm">
                      <div class="price"><span class="text-white mb-2">{{ plan.name }}</span>
                        <h2 class="display-3">{{ plan.name }}</h2>
                        <span class="badge bg-light text-dark rounded-pill mx-1" v-for="(t, j) in JSON.parse(plan.risk_type)" :key="i + '' + j">{{ t }}</span>
                      </div>
                      <!-- Pricing Desc -->
                      <div class="pricing-desc">
                        <ul class="ps-0">
                          <li><i class="bi bi-check-lg me-2"></i>{{ auth() ? baseCurrency : plan.base_currency }} {{ rates() && plan && activeRate && Object.keys(activeRate).length > 0 ? plan.min_amount * (activeRate.buy_rate / rates()[plan.base_currency].buy_rate) : plan.min_amount }} - {{ auth() ? baseCurrency : plan.base_currency }} {{ rates() && plan && activeRate && Object.keys(activeRate).length > 0 ? plan.max_amount * (activeRate.buy_rate / rates()[plan.base_currency].buy_rate) : plan.max_amount }}</li>
                          <li><i class="bi bi-check-lg me-2"></i>{{ plan.duration }} {{ plan.duration_unit }}s payback period. </li>
                          <li><i class="bi bi-check-lg me-2"></i>Payback starts {{ plan.wait || 1 }} {{ plan.wait_unit }}(s) after loan disbursment. </li>
                          <li><i class="bi bi-check-lg me-2"></i>{{ plan.take_profit }}% interest. </li>
                          <template v-for="(d, i) in (plan.description || '').split('-')" :key="i">
                            <li v-if="d.trim() !== ''"><i class="bi bi-check-lg me-2"></i>{{ d }}</li>
                          </template>
                          <template v-for="(d, i) in (plan.how_to_buy || '').split('-')" :key="i">
                            <li v-if="d.trim() !== ''"><i class="bi bi-check-lg me-2"></i>{{ d }}</li>
                          </template>
                        </ul>
                      </div>
                      <!-- Purchase -->
                      <div class="purchase">
                        <button class="btn btn-warning btn-lg btn-creative w-100" data-bs-toggle="modal" data-bs-target="#add-phone" v-if="!userProfile().phone || canTakeLoan" type="button">Take Loan Now</button>
                        <button class="btn btn-warning btn-lg btn-creative w-100" v-else @click="startLoanRequest(plan)">Take Loan Now</button>
                        <!-- <small class="d-block text-white mt-2 ms-1">No credit card required*</small> -->
                      </div>
                    </div>
                  </div>
                </template>

                <div v-if="loaders().getPlan">
                  <div class="d-flex flex-column justify-content-center text-center">
                    <div class="my-4 text-center">loading plans</div>
                    <br/>
                    <div class="mb-4 text-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <modal title="Confirm Loan Plan" id="start-loan-modal">
        <div v-if="!stage" class="overflow-auto" style="height: 470px;">
          <div class="text-center"> 
            <h6>
              Terms and Condition
            </h6>
          </div>
          <div>
            <ol>
              <li><b>Eligibility:</b>
                <p>I confirm that I meet the eligibility criteria set by the community for loan services.</p>
              </li>
              <li><b>Loan Approval:</b>
                <p>I understand that loan approval is subject to verification of the information provided and compliance with eligibility criteria.</p>
              </li>
              <li><b>Loan Terms: </b>
                <p>I have reviewed and agree to the specified loan terms, including interest rates, repayment schedules, and any additional fees.</p>
              </li>
              <li><b>Repayment Responsibilities: </b>
                <p>I am obligated to repay the loan amount according to the agreed-upon schedule.</p>
              </li>
              <li><b>Default and Consequences: </b>
                <p>I understand that defaulting on loan payments may result in additional charges or legal action. The company and community reserve the right to take any necessary and lawful actions to recover the outstanding loan amount, including but not limited to seizing, keeping, or auctioning the properties of the member.</p>
              </li>
              <li><b>Communication: </b>
                <p>I will promptly communicate any difficulties in meeting repayment obligations.</p>
              </li>
              <li><b>Confidentiality: </b>
                <p>I acknowledge that my personal and financial information will be treated with confidentiality.</p>
              </li>
              <li><b>Community Support: </b>
                <p>I am encouraged to support fellow community members and foster a positive loan repayment environment.</p>
              </li>
              <li><b>Guarantors: </b>
                <p>Guarantor 1 and Guarantor 2 agree to support and guarantee the repayment of the loan in case of default by the borrower.</p>
              </li>
              <li><b>Termination of Membership: </b>
                <p>I understand that membership in the loan community may be terminated for violation of community guidelines or repeated defaults.</p>
              </li>
              <li><b>Dispute Resolution: </b>
                  <p>I agree to resolve disputes related to loans or community matters through community-established procedures.</p>
              </li>
              <li><b>Amendments to Terms and Conditions: </b>
                  <p>I acknowledge that the community reserves the right to update these terms and conditions, with notifications provided.</p>
              </li>
            </ol>
            <p>
              I, {{ auth().name }}, hereby acknowledge and agree to the following terms and conditions of the loan provided by Redge.
            </p>
            <div class="text-center my-1">
              <app-button @click="stage = stages.LOAN_FORM" :title="'I Agree'" :loading="loaders().subscribe" :disabled="loaders().subscribe" ></app-button>
            </div>
          </div>
        </div>

        <div v-else-if="stage === stages.LOAN_FORM" class="overflow-auto" style="height: 400px;">
          <div class="text-center"> 
            <h6>
              Confirm {{ plan.name ? plan.name.toLowerCase() : "" }} plan subsciption
            </h6>
            <div>
              Grace period {{ plan.wait }} {{ plan.wait_unit + ( +plan.wait === 1 ? "": "s") }}.<br/>
              Pay {{ (+plan.duration === 1 ? "": "every ") }} {{ plan.duration }} {{ plan.duration_unit + ( +plan.duration === 1 ? "": "s") }} 
              after grace period.
            </div>
          </div>

          <div class="form-group">
              <label class="form-label" for="exampleInputnumber">Reason for loan?</label>
              <input class="form-control form-control-clicked" id="exampleInputnumber" v-model="v$.name.$model"  type="text" placeholder="House project ...">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
          </div>

          <div class="form-group" v-if="name&&name.length>=3">
              <label class="form-label" for="exampleInputnumber">Loan amount</label>
              <input class="form-control form-control-clicked" id="exampleInputnumber" v-model="v$.amount.$model"  type="number" placeholder="0">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
          </div>

          <div v-if="amount>100">
            <!-- <label class="form-label" for="airtime-network">Wallet</label> -->
            <div class="bg-warning text-dark rounded mb-2 p-2">
              <div class="mb-1">
                <small>Loan recovery will be deducted from your card by default, but you can check the box below to deduct from wallet</small>
              </div>
              <label class="form-check-label" for="funding-source">
                <input class="form-check-input" v-model="useWallet" id="funding-source" type="checkbox" aria-label="aitime-number"/>&nbsp;
                deduct loans from my wallet <i class="bi bi-wallet"></i>
              </label> 
            </div>
            <div class="form-group mb-3" v-if="useWallet">
              <div class="input-group-text select-input">
                <select class="form-select" :disabled="funding_source==='card'" id="transfer-wallet-fiat" name="transfer-wallet-fiat" aria-label="transfer-wallet-fiat" v-model="v$.funding_source.$model">
                  <!-- <option v-for="(w, i) in Object.values(account().wallet)" :key="i" :value="w.base_currency">{{ w.base_currency }} wallet</option> -->
                  <option v-for="(w, i) in ['NGN']" :key="i" :value="w">{{ w }} wallet {{ `(${account() && account().wallet ? account().wallet[w]?.ledger_balance  : '0'})`}}</option>
                </select>
              </div>
            </div>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.funding_source.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="text-secondary fs-6" v-if="amount>0">
            Your {{ funding_source }} {{ funding_source === "card" ? "" : " wallet" }} will be charged 
            {{ formatter(ffs).format((amount + (amount * plan.take_profit * 0.01 )) / plan.duration ) }} every 
            {{ plan.duration_unit }} for {{ plan.duration }} {{ plan.duration === 1 ? plan.duration_unit : `${plan.duration_unit}s` }} starting from 
            {{ moment(new Date).add((plan.wait || 0), (plan.wait_unit || 'd')).format('DD, MMM, YY') }}
          </div>

          <div class="form-check" v-if="amount>0">
            <input class="form-check-input" id="confirm" type="checkbox" v-model="v$.confirm.$model">
            <label class="form-check-label" for="confirm">confirm action</label>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.confirm.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="text-center my-1">
            <!-- <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button> -->
            <app-button @click="stage = stages.RECIEPT" :title="'proceed'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false || !amountWithinRange" ></app-button>
          </div>
        </div>

        <div class="card invoice-card shadow overflow-auto" v-else-if="stage === stages.RECIEPT" style="height: 400px;">
          <div class="card-body">
            <!-- Download Invoice -->
            <div class="download-invoice text-end mb-3">
              <a class="btn btn-sm btn-primary me-2" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-file-earmark-pdf"></i> PDF
              </a>
              <a class="btn btn-sm btn-light" href="#" @click="printDiv('invoice-div')">
                <i class="bi bi-printer"></i> Print
              </a>
            </div>

            <div id="invoice-div">
              <!-- Invoice Info -->
              <div class="invoice-info text-end mb-4">
                <h5 class="mb-1 fz-14">{{ plan.name }} | {{ name }}</h5>
                <h6 class="fz-12">{{ plan.duration }} {{ plan.duration_unit }}</h6>
                <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
              </div>

              <!-- Invoice Table -->
              <div class="invoice-table">
                <div class="table-responsive">
                  <table class="table table-bordered caption-top">
                    <caption>Payment plan</caption>
                    <thead class="table-light">
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Q.</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr v-for="(save, i) in plan.duration" :key="i"> -->
                      <tr>
                        <td>1.</td>
                        <td>{{ plan.name }} loan plan subscription for {{ plan.duration }} {{ plan.duration_unit }}</td>
                        <td>{{ plan.duration }} {{ plan.duration_unit }}s</td>
                        <td>{{ formatter(ffs).format(amount) }}</td>
                        <td>{{ formatter(ffs).format(amount / plan.duration) }}</td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>{{ plan.name }} interest rate</td>
                        <td>{{ plan.take_profit }}%</td>
                        <td> 1 </td>
                        <td>{{ formatter(ffs).format(amount * plan.take_profit * 0.01) }}</td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>{{ plan.name }} request fee</td>
                        <td>1</td>
                        <td> 1 </td>
                        <td>{{ formatter(ffs).format(plan.request_fee || 100) }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="table-light">
                      <tr>
                        <td class="text-end" colspan="4">Total:</td>
                        <td class="text-end">{{ formatter(ffs).format(amount + (amount * plan.take_profit * 0.01)) }}</td>
                      </tr>
                      <tr>
                        <!-- <td class="text-end" colspan="4">Fee {{ funding_source === 'card' ? '(1.4% + 50)' : '0'}}:</td> -->
                        <!-- <td class="text-end">{{ ffs }} {{ funding_source === 'card' ? Math.round((((amount) * 0.014) + 150 ) * 10) / 10 : 100 }}</td> -->
                        <td class="text-end" colspan="4">Fee:</td>
                        <td class="text-end">{{ ffs }} {{ plan.request_fee || 100 }}</td>
                      </tr>
                      <tr>
                        <td class="text-end" colspan="4">Grand Total:</td>
                        <!-- <td class="text-end">{{ formatter(ffs).format((amount + (amount * plan.take_profit * 0.01)) + ( funding_source === 'card' ? (amount * 0.014) + 150 : 100 )) }}</td> -->
                        <td class="text-end">{{ formatter(ffs).format((amount + (amount * plan.take_profit * 0.01)) + ( plan.request_fee || 100 )) }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div class="text-center my-1 mb-8">
              <app-button @click="subscribeToPlan" :title="'Confirm'" :loading="loaders().subscribe" :disabled="loaders().subscribe || v$.amount.$invalid || v$.confirm.$model === false" ></app-button>
            </div>

            <p class="mb-8 text-sm">Notice: This is auto generated invoice.</p>
          </div>
        </div>
      </modal>

      <modal title="Add Phone Number" id="add-phone">
        <div class="">
          <form>
            <div class="form-group mb-3" v-if="!hasCode">
              <label class="form-label" for="addPhoneInput">Add phone number</label>
              <input class="form-control" id="addPhoneInput" v-model="v$.phone.$model" type="text" placeholder="07034567...">
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.phone.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div v-else>
              <div class="form-group mb-3">
                <label class="form-label" for="otp-code">OTP Code</label>
                <input class="form-control" id="otp-code" v-model="v$.code.$model" type="text" placeholder="">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.code.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div>
                An OTP code has been sent to {{ phone }} 
                <span v-if="duration > 0">
                  resend in {{ Math.floor(duration/60) }}m:{{ duration%60 }}s
                </span>
                <span @click="getCode()" class="cursor-pointer pointer text-success" v-else>
                  resend
                </span>
              </div>
            </div>
            
            <button 
              class="btn btn-success w-100" 
              type="button"
              @click="getCode()" 
              :disabled="loaders().phoneCode || v$.phone.$invalid" 
              v-if="!hasCode"
            >Send</button>

            <button 
              class="btn btn-success w-100" 
              type="button"
              @click="updateProfile()" 
              :disabled="loaders().updatePhone || v$.code.$invalid" 
              v-else 
            >Add</button>
          </form>
        </div>
      </modal>

      <modal-business id="join-loan-cluster" initStage="select_cluster_state"></modal-business>
      
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import Modal from '@/components/common/modal/Modal.vue'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required, alphaNum, numeric, minLength, maxLength } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
import AppButton from '@/components/common/form/AppButton.vue';
import { PLAN_TYPES, printDiv, formatter } from '@/utils';
import ModalBusiness from '@/components/common/modal/ModalBusiness.vue'
import moment from 'moment';
const toaster = createToaster({ /* options */ });
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

export default {
  name: 'LoanList',
  components: {
    PageTemplate,
    Modal,
    AppButton,
    ModalBusiness
  },
  watch: {
    //
    useWallet: function (useW, oldUseW) {
      if ( useW ) {
        this.funding_source = this.baseCurrency
        this.activeRate = this.rates()[this.baseCurrency] || {}
      } else {
        this.funding_source = "card"
      }
    },
    funding_source(fs, ofs) {
      // if (typeof(this.rates()[fs === "card" ? this.auth() ? this.auth().settings.base_currency : "NGN" : fs]) === 'undefined') {
      //   toaster.show( fs + " not supported yet!" );
      if ( fs === "card" ) {
        this.ffs = ofs;
      } else {
        this.ffs = fs;
        this.activeRate = this.rates()[this.baseCurrency] || {}
      }
    }
  },
  computed: {
    amountWithinRange() {
      const maxAmount = this.rates() && this.activeRate ? this.plan.max_amount * (this.activeRate.buy_rate / this.rates()[this.plan.base_currency].buy_rate) : this.plan.max_amount || 0 
      const minAmount = this.rates() && this.activeRate ? this.plan.min_amount * (this.activeRate.buy_rate / this.rates()[this.plan.base_currency].buy_rate) : this.plan.min_amount || 0
      return minAmount <= this.amount && this.amount <= maxAmount;
    },
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      ffs: (Object.values(this.auth()).length > 0 && this.auth().settings) ? this.auth().settings.base_currency : "NGN",
      useWallet: null,
      plan: {},
      amount: null,
      confirm: false,
      funding_source: "card",
      name: "",
      isSuccessful: false,
      phone: "",
      duration: 0,
      code: "",
      hasCode: false,
      canTakeLoan: false,
      baseCurrency: this.auth() && this.auth().settings ? this.auth().settings.base_currency : "NGN",
      activeRate: this.rates() ? this.rates()["NGN"] : {},
      stage: null,
      stages: {
        LOAN_FORM: "loan_form",
        RECIEPT: "reciept",
      }
    }
  },
  validations() {
    return {
      amount: { required, numeric, min: minLength(1) },
      confirm: { required },
      funding_source: {alphaNum},
      name: { required, min: minLength(3)},
      phone: {required, min: minLength(11), max: maxLength(11), numeric},
      code: {min: minLength(6), max: maxLength(6)}
      // "user_uuid", "plan_id", "amount", "funding_source", "type", "name", "reference", "duration", "duration_unit"
    }
  },
  mounted() {
    this.getAllPlans();

    if ( Object.values(this.rates()).length === 0 ) {
      this.getRates()
    }
    if ( Object.values(this.account()).length === 0 ) {
      this.myAccount()
    }
    if ( Object.keys(this.userProfile()).length === 0 ) {
      this.getProfile()
    }
  },
  methods: {
    ...mapGetters([
      "loanPlans",
      "loaders",
      "auth",
      "account",
      "rates",
      "userProfile"
    ]),
    ...mapActions([
      "confirmFlutterPayment",
      "getAllPlans",
      "subscribePlan",
      "myAccount",
      "getRates",
      "getQuestions",
      "getProfile",
      "updatePhone",
      "phoneCode",
      "saveProfile"
    ]),
    startLoanRequest( plan ) {
      this.stage = null;
      this.plan = plan;
      const {clusters = []} = this.auth();
      const modalId = plan.is_group && clusters.length === 0 ? "join-loan-cluster" : "start-loan-modal"
      const loanModal = document.getElementById(modalId);
      const _loanModal = bootstrapBundleMin.Modal.getOrCreateInstance(loanModal)
      _loanModal.toggle();
    },
    initiateLoanSubscription() {
      const successCallback = (position) => {
        const { coords } = position;
        const { latitude, longitude } = coords
        const self = this;
        self.saveProfile({
          latitude: String(latitude), 
          longitude: String(longitude)
        }).then(() => {
          self.subscribeToPlan()
        });
      };

      const errorCallback = (error) => {
        console.log(error);
        toaster.error("We cannot give loans without accepting geo location. Kindly refresh and accept.")
      };

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    },
    subscribeToPlan() {
      // const minAmt = 0;
      const minAmt = this.rates() && this.activeRate ? this.plan.min_amount * (this.activeRate.buy_rate / this.rates()[this.plan.base_currency].buy_rate) : this.plan.min_amount;
      
      if ( this.amount < minAmt ) {
        toaster.error("because of your selected loan plan, use an amount greater than " + this.ffs + " " + minAmt );
        return
      }

      // if ( this.funding_source !== "card" && this.account().wallet.NGN.ledger_balance < this.amount ) {
      //   toaster.show("your balance is too low. " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
      //   return
      // }

      const userUuid = window.localStorage.getItem("uuid");
      const data = {
        user_uuid: userUuid,
        plan_id: this.plan.id,
        amount: this.amount,
        type: PLAN_TYPES.loan,
        funding_source: this.funding_source,
        name: this.name
      }
      const self = this;
      
      self.subscribePlan(data).then( res => {
        console.log(res);
        if ( self.funding_source !== "card" ) {
          self.$router.push({
            path: "/loan-subscribe-success"
          });
        } else {
          self.isSuccessful = true;
          // self.makePayment(res.reference) // we we initiate the card for recurrent
          self.$router.push({
            path: "/loan-subscribe-success"
          });
        }
      }).catch( e => {
        const { message } = e
        toaster.error(message)
      });
    },
    makePaymentCallback(response) {
      const self = this;
      this.confirmFlutterPayment(response).then( res => {
        self.isSuccessful = true;
        self.$router.push({
          path: "/loan-subscribe-success"
        });
        
      });
    },
    closedPaymentModal() {
      console.log('payment is closed');
      if ( this.isSuccessful ) {
        this.$router.push({
          path: "/loan-subscribe-success"
        });
      }
    },
    makePayment(ref) {
      const self = this;
      const paymentData = {
        tx_ref: ref,
        amount: this.amount + 50,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        meta: {
          'counsumer_id': self.auth().id,
          'consumer_mac': window.localStorage.getItem("uuid")
        },
        customer: {
          email: self.auth().email,
          name: self.auth().name
        },
        customizations: {
          title: 'Redge Loan Plan',
          description: ( self.plan.name ? self.plan.name.toLowerCase() : "" ) + " plan subsciption",
          logo: 'https://app.redge.ng/img/redge.3fd2356f.png'
        },
        payment_plan: this.plan.reference,
        callback: self.makePaymentCallback,
        onclose: self.closedPaymentModal
      }
      if ( self.auth().profile && self.auth().profile.phone ) {
        paymentData.customer.phonenumber = self.auth().profile.phone
      }
      self.$launchFlutterwave(paymentData)
    },
    startCountDown() {
      const self = this;
      const interval = setInterval(() => {
        self.duration = self.duration - 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval)
      }, self.duration * 1000);
    },
    getCode() {
      const self = this
      if ( this.phone.match(/\d/g).length===11 ) {
        this.phoneCode({
          phone: this.phone
        })
        .then( () => {
          toaster.success('OTP sent to ' + self.phone);
          self.duration = 90;
          self.startCountDown()
          self.hasCode = true
        })
        .catch( err => {
          self.errors = err.data
        })
      } else {
        toaster.error("phone number not valid")
      }
    },
    updateProfile() {
      const self = this
      this.updatePhone({
        phone: this.phone,
        code: this.code
      })
        .then( () => {
          toaster.success('Phone successfully updated');
          self.canTakeLoan = true;
        })
        .catch( err => {
          self.errors = err.data
        })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.price-table-one .single-price-content{
  background-color: #061238;
}
.img-plan {
  width: 70px;
}
.price-table-one .nav-tabs .nav-link.active  {
  background-color: #061238;
    color: #ffffff;
    border-color: #061238;
}
.price-table-one .nav-tabs .nav-link {
  color: #061238;
}

.price-table-one .tab-content .tab-pane:nth-child(2) .single-price-content {
    background-color: var(--jw-text-success);
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link.active {
    background-color: var(--jw-text-success);
    border-color: var(--jw-text-success);
    color: #ffffff;
}
.price-table-one .nav-tabs .nav-item:nth-child(2) .nav-link {
    color: var(--jw-text-success);
}
.input-group-text.select-input {
  padding: 0rem;
  border: 0px solid #ced4da;
}
</style>
