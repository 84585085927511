export const mapStatusTextColor = (status) => {

    const colorMapping = {
        "INITIATED": "text-dark",
        "PROCESSING": "text-secondary",
        "PENDING": "text-dark",
        "APPROVED": "text-primary",
        "ACTIVE": "text-dark",
        "FAILED": "text-danger",
        "CANCELLED": "text-danger",
        "BAD": "text-danger",
        "TERMINATED": "text-danger",
        "DECLINED": "text-danger",
        "SUCCESSFUL": "text-success",
        "COMPLETED": "text-success",
        "RETRY": "text-dark"
    };
    return colorMapping[status];
}


export const mapStatusBgColor = (status) => {
    const colorMapping = {
        "INITIATED": "bg-dark",
        "PROCESSING": "bg-secondary",
        "PENDING": "bg-secondary",
        "APPROVED": "bg-primary",
        "ACTIVE": "bg-dark",
        "FAILED": "bg-danger",
        "CANCELLED": "bg-danger",
        "BAD": "bg-danger",
        "TERMINATED": "bg-danger",
        "DECLINED": "bg-danger",
        "SUCCESSFUL": "bg-success",
        "COMPLETED": "bg-success",
        "RETRY": "bg-dark"
    };
    return colorMapping[status];
}









export const mapStatusCodeColor = (status) => {

    const code = status.status.code || status.code || "xx"

    const colorMapping = {
        "99": "text-red",
        "00": "text-green",
        "97": "text-blue",
        "89": "text-indigo",
        "98": "text-red",
        "96": "text-gray",
        "94": "text-red",
        "92": "text-purple",
        "91": "text-purple",
        "90": "text-pink",
        "88": "text-red",
        "xx": "text-yellow"
    };
    return colorMapping[code];
}

export const mapStatusCodeBadgeColor = (status) => {

    const code = status.status.code || status.code || "xx"

    const colorMapping = {
        "99": "danger",
        "00": "success",
        "97": "primary",
        "89": "text-indigo",
        "98": "danger",
        "96": "text-gray",
        "94": "danger",
        "92": "text-purple",
        "91": "text-purple",
        "90": "text-pink",
        "88": "danger",
        "xx": "text-yellow"
    };
    return colorMapping[code];
}