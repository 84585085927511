<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay pb-4" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-4 py-5">
            <h3 class="mb-3 text-white">Verification</h3>
          </div>
        </div>
      </div>

      <div class="container verification-area">
        <div class="card user-info-card mb-3">

          <div class="card-body align-items-center">
            <div class="">
              <div @click="handleUpdatePhone">
                <div class="d-flex align-items-center justify-content-between" :class="{'text-danger': !userProfile().phone, 'text-success': userProfile().phone, 'text-secondary': loaders().fetchProfile}">
                  <p class="mb-1 ">STEP 1: Phone Number</p>
                  <div>
                    <span v-if="loaders().fetchProfile"><i>loading...</i></span>
                    <span v-else-if="userProfile().phone">completed</span>
                    <span v-else>incomplete</span>
                    &nbsp;
                    <i class="bi bi-lightning-charge text-xs"></i>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="">
              <router-link to="/profile?session=about&edit=true">
                <div class="d-flex align-items-center justify-content-between" :class="{'text-danger': inCompleteProfile, 'text-secondary': !userProfile().phone, 'text-success': !inCompleteProfile, 'text-secondary': loaders().fetchAuth}">
                  <p class="mb-1 ">STEP 2: Update Profile</p>
                  <div>
                    <span v-if="loaders().fetchAuth"><i>loading...</i></span>
                    <span v-else-if="inCompleteProfile && !userProfile().phone">pending</span>
                    <span v-else-if="!inCompleteProfile">completed</span>
                    <span v-else>incomplete</span>
                    &nbsp;
                    <i class="bi bi-person-check text-xs"></i>
                  </div>
                </div>
              </router-link>
            </div>
            <hr/>
            <div class="">
              <router-link to="/profile?session=address&edit=true">
                <!-- <div 
                  class="d-flex align-items-center justify-content-between" 
                  :class="{
                    'text-secondary': auth().meta && Object.values(auth().meta.pending_addr_verification).length > 0,
                    'text-danger': !settings().address_verified_at, 
                    'text-success': settings().address_verified_at, 
                    'text-secondary': loaders().fetchAuth
                  }"
                >
                  <p class="mb-1 ">STEP 3: Address</p>
                  <div>
                    <span v-if="loaders().fetchAuth"><i>loading...</i></span>
                    <span v-else-if="!settings().address_verified_at && Object.values(auth().meta.pending_addr_verification).length > 0">pending</span>
                    <span v-else-if="settings().address_verified_at">completed</span>
                    <span v-else>incomplete</span>
                    &nbsp;
                    <i class="bi bi-patch-check-fill text-xs"></i>
                  </div>
                </div> -->
                <div 
                  class="d-flex align-items-center justify-content-between text-secondary">
                  <p class="mb-1 ">STEP 3: Address</p>
                  <div>
                    <span>waived</span>
                    &nbsp;
                    <i class="bi bi-patch-check-fill text-xs"></i>
                  </div>
                </div>
              </router-link>
            </div>
            <hr/>
            <div class="">
              <div @click="handleUpdateIdentity">
                <div 
                  class="d-flex align-items-center justify-content-between" 
                  :class="{
                    'text-secondary': loaders().fetchAuth,
                    'text-danger': !auth().meta || (auth().meta && !auth().meta.is_verified),  
                    'text-secondary': !settings().address_verified_at,
                    'text-success': auth().meta && auth().meta.is_verified,
                  }"
                >
                  <p class="mb-1">STEP 4: Identity</p>
                  <div>
                    <span v-if="loaders().fetchAuth"><i>loading...</i></span>
                    <span v-else-if="(!auth().meta || (auth().meta && !auth().meta.is_verified)) && !settings().address_verified_at">pending</span>
                    <span v-else-if="auth().meta && auth().meta.is_verified">completed</span>
                    <span v-else>incomplete</span>
                    &nbsp;
                    <i class="bi bi-hand-index-fill text-xs"></i>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="">
              <div @click="$refs.transactionPin.handleResetPin()">
                <div 
                  class="d-flex align-items-center justify-content-between"
                  :class="{
                    'text-danger': !hasSetNGNPin, 
                    'text-secondary': !auth().meta || (auth().meta && !auth().meta.is_verified), 
                    'text-success': hasSetNGNPin,
                    'text-secondary': loaders().fetchAuth,
                  }"
                >
                  <p class="mb-1 ">STEP 5: Transaction Pin</p>
                  <div>
                    <span v-if="loaders().fetchAuth"><i>loading...</i></span>
                    <span v-else-if="(!auth().meta || (auth().meta && !auth().meta.is_verified)) && !hasSetNGNPin">pending</span>
                    <span v-else-if="!hasSetNGNPin">incomplete</span>
                    <span v-else>completed</span>
                    &nbsp;
                    <i class="bi bi-key text-xs"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- <hr/>
            <div class="">
              <div @click="initLiveNessTest">
                <div 
                  class="d-flex align-items-center justify-content-between" 
                  :class="{
                    'text-danger': !settings().liveness_verified_at, 
                    'text-secondary': !hasSetNGNPin,
                    'text-success': settings().liveness_verified_at
                  }"
                >
                  <p class="mb-1 ">STEP 6: Liveness</p>
                  <div>
                    <span v-if="loaders().fetchSettings"><i>loading...</i></span>
                    <span v-else-if="hasSetNGNPin">pending</span>
                    <span v-else-if="!settings().liveness_verified_at">incomplete</span>
                    <span v-else>completed</span>
                    &nbsp;
                    <i class="bi bi-tv-fill text-xs"></i>
                  </div>
                </div>
              </div>
            </div> -->
            <hr/>
            <div class="">
              <div data-bs-toggle="modal" data-bs-target="#add-bank-account">
                <div 
                  class="d-flex align-items-center justify-content-between" 
                  :class="{
                    'text-danger': default_bank.length === 0, 
                    'text-secondary': !settings().liveness_verified_at, 
                    'text-success': default_bank.length > 0,
                    'text-secondary': loaders().fetchBankAccount
                  }"
                >
                  <p class="mb-1 ">STEP 7: Bank Account</p>
                  <div>
                    <span v-if="loaders().fetchBankAccount"><i>loading...</i></span>
                    <span v-else-if="default_bank.length === 0 && !settings().liveness_verified_at">pending</span>
                    <span v-else-if="default_bank.length > 0">completed</span>
                    <span v-else>incomplete</span>
                    &nbsp;
                    <i class="bi bi-bank text-xs"></i>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div>
          <div class="card user-info-card mb-3" v-if="hasCompletedVerification">
            <div class="card-body align-items-center text-center justify-center items-centers">
              <!--  -->
              <div>
                This account is verified
              </div>
              <success-icon></success-icon>
            </div>
          </div>
          <div class="card user-info-card mb-3" v-else>
            <div class="card-body align-items-center text-center justify-center items-centers">
              <div>
                This account is not yet verified
              </div>
              <error-icon></error-icon>
            </div>
          </div>
        </div>

      </div>
    </div>

    <modal title="Identification" id="identification">
      <form>
        <div class="form-group mb-3">
          <label class="form-label" for="identification">Identification Method</label>
          <select class="form-control" id="identification" v-model="v$.identification_mode.$model">
            <option v-for="(method, i) in ['bvn', 'nin']" :key="i" :value="method">{{ method }}</option>
          </select>
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.identification_mode.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="form-label" for="identification_value">Enter {{ identification_mode }}</label>
          <input class="form-control" id="identification_value" type="text" v-model="v$.identification_value.$model" placeholder="910..." >
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.identification_value.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        
        <app-button 
          @click="verifyIdentify" 
          :title="`Verify (${identification_mode})`" 
          :loading="loaders().fetchProfile || loaders().verifyViaBVN || loaders().verifyByNIN || loaders().verifyByPhone" 
          :disabled="loaders().fetchProfile || loaders().verifyViaBVN || loaders().verifyByNIN || loaders().verifyByPhone || v$.identification_value.$invalid" 
        ></app-button>
      </form>
    </modal>
    <modal-change-phone id="update-phone"></modal-change-phone>
    <modal-transaction-pin id="transaction-pin" ref="transactionPin"></modal-transaction-pin>
    <modal-add-bank-account 
      id="add-bank-account" 
      bank_type="commercial"
      :is_default="true"
      :active_mandate="true"
    ></modal-add-bank-account>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import useVuelidate from '@vuelidate/core';
import Modal from '@/components/common/modal/Modal.vue'
import AppButton from '@/components/common/form/AppButton.vue';
import { required } from '@vuelidate/validators';
import ModalChangePhone from '@/components/common/modal/ModalChangePhone.vue'
import ModalTransactionPin from '@/components/common/modal/ModalTransactionPin.vue'
import SuccessIcon from '@/components/common/notification/SuccessIcon.vue'
import ErrorIcon from '@/components/common/notification/ErrorIcon.vue'
import ModalAddBankAccount from '@/components/common/modal/ModalAddBankAccount.vue';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';

export default {
  name: 'VerificationPage',
  components: {
    PageTemplate,
    AppButton,
    Modal,
    ModalChangePhone,
    ModalTransactionPin,
    SuccessIcon,
    ErrorIcon,
    ModalAddBankAccount
  }, 
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      identification_mode: "bvn",
      identification_value: "",
      errors: {},
      banner: banner || require("@/assets/images/common/22.jpeg"),
      default_bank: []
    }
  },
  validations() {
    return {
      identification_mode: {required},
      identification_value: {required},
    }
  },
  mounted() {
    const self = this
    if (Object.keys(this.userProfile()).length === 0) {
      this.getProfile().then( res => {
        // self.mprofile = res
      });
    }
    this.getBankAccounts({get_default: true}).then( res => {
      this.default_bank = res.data || [res] || [];
    })
  },
  computed: {
    hasSetNGNPin() {
      const trnxPin = this.$store.state.transactionPinStatus
      return trnxPin && trnxPin["NGN"] === 'pin_set';
    },
    hasCompletedVerification() {
      return this.userProfile().phone &&
      !this.inCompleteProfile &&
      this.settings().address_verified_at && 
      this.auth().meta && this.auth().meta.is_verified && 
      this.hasSetNGNPin 
      // && 
      // this.settings().liveness_verified_at
    },
    inCompleteProfile() {
      const profile = this.userProfile();
      if ( !profile.first_name || !profile.last_name || !profile.dob ) {
        return true;
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      "getProfile",
      "verifyViaBVN",
      "verifyViaNIN",
      "confirmViaBVN",
      "getBankAccounts"
    ]),
    ...mapGetters([
      "loaders",
      "user",
      "userProfile",
      "auth",
      "appConfig",
      "settings"
    ]),
    ...mapMutations(["updateSettings"]),
    verifyIdentify() {
      const data = this.getDetails();
      data[this.identification_mode] = this.identification_value
      let query = null
      switch (this.identification_mode) {
        case "bvn":
          query = this.verifyViaBVN(data);
          break;
        case "nin":
          query = this.verifyViaNIN(data);
          break;
        case "phone":
          query = this.verifyViaNIN(data);
          break;
        default:
          break;
      }
      if (query !== null) {
        query.then(() => {
          const identification = document.getElementById("identification");
          const _identification = bootstrapBundleMin.Modal.getInstance(identification)
          _identification.hide();
          toaster.success("Identify verification processed")
        }).catch( e => {
          const { message } = e;
          this.errors = { message }
          toaster.error("We are sorry, an error occured this time.")
        })
      }
    },
    getDetails() {
      const data = {}
      const profile = this.userProfile();
      if ( profile.first_name && profile.last_name) {
        data.firstname = profile.first_name;
        data.lastname = profile.last_name;
      } else {
        const names = this.user.name.split(" ");
        data.firstname = names[0];
        data.lastname = names[1] || ""
      }

      const date = new Date(profile.dob);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      data.dob = `${day}-${month}-${year}`;
      return data;
    },
    initLiveNessTest() {
      const { first_name, last_name } = this.userProfile();
      const { uuid, email } = this.auth();
      const settings = this.settings();
      const self = this;
      try {
        IdentityKYC.verify({
          merchant_key: "live_pk_smkr1bLH7bL5UbpdfScF0hCTbTjpNSVV9FZI5Ua", // your public key
          first_name,
          last_name,
          user_ref: uuid,
          is_test: false, 
          email,
          config_id: "0045122c-7592-4657-8956-4541bb2232ed",
          callback: function (response) {
            if(response.status =='success'){
              // process your response data here
              // please note that the response will also be sent to your webhook URL.
              self.updateSettings({...settings, liveness_verified_at: new Date()})
            }
            else{
              // process error message here
            } 
          },
        })
      } catch (e) {}

      // new VerifyMePopupSetup({
      //   lastname: "john",
      //   firstname: "doe",
      //   phone: "08128730170",
      //   identityType: "bvn",
      //   identityNumber: "10000000001",
      //   key: "pk_live_e2164a7f827b62b38ea6974b9d3a70f65b0b00ed579",
      //   reference: "_unique_reference_",
      //   widgetType: "LIVENESS",
      //   productCode: "PC",
      //   callback: function (response) {
      //     console.log(response)
      //     if (response.status == 'success') {
      //       // on success
      //       // Use response.reference to query liveness verification status
      //     } else {
      //       // on failed
      //     }
      //   }
      // });
    },
    handleUpdatePhone() {
      if (this.userProfile().phone) {
        toaster.info("Phone already set")
        return;
      }
      const phoneModal = document.getElementById("update-phone");
      const _phoneModal = bootstrapBundleMin.Modal.getOrCreateInstance(phoneModal)
      _phoneModal.toggle();
    },
    handleUpdateIdentity() {
      if (this.auth() && this.auth().meta && this.auth().meta.is_verified) {
        toaster.info("Identity already verified")
        return;
      }
      const identification = document.getElementById("identification");
      const _identification = bootstrapBundleMin.Modal.getOrCreateInstance(identification)
      _identification.toggle();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px;
  overflow: hidden;
}
.verification-area {
  position: relative;
  margin-top: -63px;
  z-index: 10;
}
a {
  text-decoration: none;
}
</style>
