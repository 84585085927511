const PlanState = {
  investmentPlans: [],
  savingPlans: [],
  loanPlans: [],
  subscribe: {},
  userInvestmentPlans: [],
  userSavingPlans: [],
  userLoanPlans: []
};

export default PlanState;
