import { mapActions, mapGetters } from 'vuex';
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export const bankTransfer = {
    data() {
        return {
          bvn: ""
        }
    },
    mounted() {
        this.getVirtualAccount();
    },
    computed: {
      ...mapGetters(["accountDetails"])
    },
    methods: {
        ...mapActions([
          "fundAccount"
        ]),
        getVirtualAccount() {
          const data = {type: "NGN"};
          if ( this.bvn ) {
            data['bvn'] = this.bvn;
          }
          const self = this;
          
          this.fundAccount(data)
          .then( res => {
            const { code } = res
            if ( +code === 25 ) {
              const bvnModal = document.getElementById('add-bvn-modal');
              const _bvnModal = bootstrapBundleMin.Modal.getOrCreateInstance(bvnModal)
              _bvnModal.show();
            } else if( +code === 400 ) {
              toaster.error("Kindly complete your profile update. i.e Gender, First name, Last Name and BVN")
            } else {
              document.getElementById("close-modal-id").click();
            }
            self.listenForPayment();
            
            const overlay = document.getElementsByClassName("modal-backdrop")[0];
            if (overlay) overlay.classList.remove(...overlay.classList)
          })
          .catch( err => {
            console.log(err)
            const overlay = document.getElementsByClassName("modal-backdrop")[0];
            if (overlay) overlay.classList.remove(...overlay.classList)  
          })
        },
        listenForPayment() {
          const userUUID = window.localStorage.getItem("uuid");
          const self = this;
          
          window.Echo.private(`transaction.credit.ngn.${userUUID}`)
            .listen('.credit.alert', function(e) {
                // console.log(e);
                const data = e.trxData || e.transaction
                if ( data && data.status && data.status.toUpperCase() === "SUCCESSFUL" ) {
                  self.$router.push({
                    path: '/bank-transfer-success', 
                    query: { amount: data.net_amount, currency: data.currency }
                  });
                  // 
                }
                toaster.success(data.description || "Transaction received")
            }).listen("pusher:subscription_error", (e) => {
                console.log(e);
            });
    
          window.Echo.channel
        },
    }
}